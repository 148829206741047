import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-modal-expanded-contacts',
  templateUrl: './modal-expanded-contacts.component.html',
  styleUrls: ['./modal-expanded-contacts.component.css']
})
export class ModalExpandedContactsComponent implements OnInit {
  userContacts: any = [];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userData: UserDataService, private dialog: MatDialog) {}
    rows: any[] = []; // Lista de filas
    positions: string[] = [
      "Representante de Ventas",
      "Técnico de Soporte",
      "Ingeniero de Software",
      "Especialista en Servicio al Cliente",
      "Asistente Administrativo",
      "Contador",
      "Especialista en Marketing Digital",
      "Analista Financiero",
      "Gerente de Proyectos",
      "Gerente de Logística",
      "Gerente de Producción",
      "Gerente de IT",
      "Gerente de Ventas",
      "Gerente de Marketing",
      "Gerente de Recursos Humanos",
      "Director de Operaciones (COO)",
      "Director Financiero (CFO)",
      "Director General (CEO)"
  ];
    prospectCompanyId: number = 1; // ID de la compañía prospectada (automático)
  ngOnInit(): void {
    this.prospectCompanyId = this.data.contact.id
    this.rows = this.data.contact.persons
    console.log(this.rows)
  }

  addRow() {
    this.rows.push({
      id: 0,
      complete_name: '',
      position: '',
      email: '',
      authority: 1,
      department: '',
      phone: '',
      prospect_company_id: this.prospectCompanyId
    });
  }

  deleteRow(index: number) {
    this.rows.splice(index, 1);
  }
  saveRow(row: any) {
    this.userData.createNewPersonCompany(row).subscribe(data => {
      console.log(data);
    })
  }


}
