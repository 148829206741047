<div class="contact-card" [class.is-editing]="isEditing">
  <!-- Botón para alternar modo de edición -->
  <div class="form-actions">
    <button (click)="toggleEditMode()" [class.editing]="isEditing">
      {{ isEditing ? 'Guardar' : 'Editar' }}
    </button>
  </div>

  <!-- Información General -->
  <div class="contact-section full-width">
    <h3>Información General</h3>
    <div *ngIf="!isEditing" class="bubble">
      <strong>Nombre:</strong> {{ editableContact.nombre_de_empresa || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Nombre:</strong>
      <input [(ngModel)]="editableContact.nombre_de_empresa" class="input-bubble" placeholder="Nombre de la Empresa" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Forma Social:</strong> {{ editableContact.forma_social || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Forma Social:</strong>
      <input [(ngModel)]="editableContact.forma_social" class="input-bubble" placeholder="Forma Social" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Actividad:</strong> {{ editableContact.actividad || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Actividad:</strong>
      <input [(ngModel)]="editableContact.actividad" class="input-bubble" placeholder="Actividad" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Subsector:</strong> {{ editableContact.subsector || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Subsector:</strong>
      <input [(ngModel)]="editableContact.subsector" class="input-bubble" placeholder="Subsector" />
    </div>
  </div>

  <!-- Localización -->
  <div class="contact-section">
    <h3>Localización</h3>
    <div *ngIf="!isEditing" class="bubble">
      <strong>Provincia:</strong> {{ editableContact.provincia || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Provincia:</strong>
      <input [(ngModel)]="editableContact.provincia" class="input-bubble" placeholder="Provincia" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Localidad:</strong> {{ editableContact.localidad || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Localidad:</strong>
      <input [(ngModel)]="editableContact.localidad" class="input-bubble" placeholder="Localidad" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Dirección:</strong> {{ editableContact.direccion || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Dirección:</strong>
      <input [(ngModel)]="editableContact.direccion" class="input-bubble" placeholder="Dirección" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Código Postal:</strong> {{ editableContact.codigo_postal || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Código Postal:</strong>
      <input [(ngModel)]="editableContact.codigo_postal" class="input-bubble" placeholder="Código Postal" />
    </div>
  </div>

  <!-- Contacto -->
  <div class="contact-section">
    <h3>Contacto</h3>
    <div *ngIf="!isEditing" class="bubble">
      <strong>Teléfono:</strong> {{ editableContact.telefono || '-' }}
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Teléfono:</strong>
      <input [(ngModel)]="editableContact.telefono" class="input-bubble" placeholder="Teléfono" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Email:</strong>
      <a *ngIf="editableContact.email" [href]="'mailto:' + editableContact.email">{{ editableContact.email }}</a>
      <span *ngIf="!editableContact.email">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Email:</strong>
      <input [(ngModel)]="editableContact.email" class="input-bubble" placeholder="Email" />
    </div>
  </div>

  <div class="contact-section">
    <h3>Redes Sociales</h3>
    <div *ngIf="!isEditing" class="bubble">
      <strong>Sitio Web:</strong>
      <a *ngIf="editableContact.sitio_web" [href]="editableContact.sitio_web" target="_blank">
        {{ editableContact.sitio_web }}
      </a>
      <span *ngIf="!editableContact.sitio_web">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Sitio Web:</strong>
      <input [(ngModel)]="editableContact.sitio_web" class="input-bubble" placeholder="Sitio Web" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Facebook:</strong>
      <a *ngIf="editableContact.facebook" [href]="editableContact.facebook" target="_blank">
        {{ editableContact.facebook }}
      </a>
      <span *ngIf="!editableContact.facebook">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Facebook:</strong>
      <input [(ngModel)]="editableContact.facebook" class="input-bubble" placeholder="Facebook" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Instagram:</strong>
      <a *ngIf="editableContact.instagram" [href]="editableContact.instagram" target="_blank">
        {{ editableContact.instagram }}
      </a>
      <span *ngIf="!editableContact.instagram">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Instagram:</strong>
      <input [(ngModel)]="editableContact.instagram" class="input-bubble" placeholder="Instagram" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>Twitter:</strong>
      <a *ngIf="editableContact.twitter" [href]="editableContact.twitter" target="_blank">
        {{ editableContact.twitter }}
      </a>
      <span *ngIf="!editableContact.twitter">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>Twitter:</strong>
      <input [(ngModel)]="editableContact.twitter" class="input-bubble" placeholder="Twitter" />
    </div>

    <div *ngIf="!isEditing" class="bubble">
      <strong>YouTube:</strong>
      <a *ngIf="editableContact.youtube" [href]="editableContact.youtube" target="_blank">
        {{ editableContact.youtube }}
      </a>
      <span *ngIf="!editableContact.youtube">-</span>
    </div>
    <div *ngIf="isEditing" class="bubble">
      <strong>YouTube:</strong>
      <input [(ngModel)]="editableContact.youtube" class="input-bubble" placeholder="YouTube" />
    </div>
  </div>

  <div class="contact-section" >
    <div class="select-container">
      Valoracion:
      <select [(ngModel)]="value" (change)="updateValue()">
        <option *ngFor="let option of options" [value]="option">{{ option }}%</option>
      </select>

    </div>
  </div>



  <div class="contact-section">
    <h3>Contactos Personas <i (click)="openModalPeople()" class="fa-solid fa-up-right-and-down-left-from-center"></i></h3>

    <!-- Modo de visualización (scrollable listado) -->
    <div *ngIf="!isEditing" class="bubble scrollable-list">
      <div style="display: flex; flex-direction: row; justify-content: space-between;" *ngFor="let person of editableContact.persons">
        <div>
        <strong>Nombre:</strong> {{ person.complete_name || '-' }} <br />
        </div>
        <div>
        <strong>Position:</strong> {{ person.position || '-' }} <br />
        </div>
        <div>
        <strong>Authority:</strong> {{ person.authority || '-' }} <br />
        </div>
        <div>
        <strong>Email:</strong>
        <a *ngIf="person.email" [href]="'mailto:' + person.email">{{ person.email }}</a>
        <span *ngIf="!person.email">-</span>
      </div>
        <hr />
      </div>
    </div>

    <!-- Modo de edición (scrollable listado) -->
    <!-- <div *ngIf="isEditing" class="bubble scrollable-list">
      <div *ngFor="let person of editableContact.persons; let i = index" class="contact-item">
        <strong>Nombre:</strong>
        <input [(ngModel)]="person.complete_name" class="input-bubble" placeholder="Nombre de la Persona" />
        <br />
        <strong>Email:</strong>
        <input [(ngModel)]="person.email" class="input-bubble" placeholder="Correo Electrónico" />
        <br />
        <button class="remove-button" (click)="removePerson(i)">Eliminar</button>
        <hr />
      </div>
      <button (click)="addPerson()">Añadir Persona</button>
    </div> -->
  </div>

  <div>

  </div>



</div>
