import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Subject } from 'rxjs';


@Injectable({
  providedIn: "root"
})
export class UserDataService {

  private notify = new Subject<any>();

  notifyObservable$ = this.notify.asObservable();
  constructor(private http: HttpClient) {
  }

  public notifyExercise(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  // saveError(error, request) {
  //   if (error) {
  //     error = JSON.stringify(error);
  //     request = JSON.stringify(request);
  //   }
  //   return this.http.post(`${environment.apiUrl}/api/user/saveError`, { 'error': error, 'req': request });
  // }

  changeEdit(user_id, role_id: string, name: string, last_name: string, email: string, phone: number, office_id: string, image?: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/changeProfileUser`, {'id': user_id, 'role_id': role_id, 'name': name, 'last_name': last_name, 'email': email, 'phone': phone, 'office_id': office_id, 'picture': image});
  }

  userCheckPassword(password: string, user_id) {
    return this.http.get(`${environment.apiUrl}/api/user/userCheckPassword`, { params: { 'user_id': user_id, 'password': password } });
  }

  selectColeccion(text){
    return this.http.get(`${environment.apiUrl}/api/user/selectColeccion`, { params: { 'text': text } });
  }

  closeTicket(chat_id){
    return this.http.post(`${environment.apiUrl}/api/user/closeTicket`, { 'chat_id': chat_id });
  }

  getAllUsersConnected(){
    return this.http.get(`${environment.apiUrl}/api/user/getAllUsersConnected`, { params: {} });
  }

  // searchContacts(query: string) {
  //   return this.http.get(`${environment.apiUrl}/api/user/searchContacts`, { params: { 'query': query } });
  // }

  // filterContacts(column: string, value: string) {
  //   return this.http.get(`${environment.apiUrl}/api/user/filterContacts`, { params: { 'column': column, 'value': value } });
  // }

  // sortContacts(column: string) {
  //   return this.http.get(`${environment.apiUrl}/api/user/sortContacts`, { params: { 'column': column } });
  // }

  getFilteredContacts(filters: { column: string; value: string }[], page: number, perPage: number) {
    return this.http.post(`${environment.apiUrl}/api/user/getFilteredContacts`, {filters, page, perPage
    });
  }

  getAllContactFromColection(filters: { column: string; value: string }[]) {
    return this.http.post(`${environment.apiUrl}/api/user/getAllContactFromColection`, {filters
    });
  }

  changeTipoAssist(chat_id, type){
    return this.http.post(`${environment.apiUrl}/api/user/changeTypeAssist`, { 'chat_id': chat_id, 'type': type });
  }

  updateContact(formData) {
    return this.http.post(`${environment.apiUrl}/api/user/editCompanyContact`, formData);
  }

  createNewProbability(percentage, prospect_company_id) {
    return this.http.post(`${environment.apiUrl}/api/user/createNewProbability`, {'percentage' : percentage, 'prospect_company_id': prospect_company_id });
  }

  createNewPersonCompany(row) {
    return this.http.post(`${environment.apiUrl}/api/user/createNewPersonCompany`, row);
  }


  elasticSearchContacts(text, column){
    return this.http.get(`${environment.apiUrl}/api/user/elasticSearchContacts`, { params: { 'text': text,
      column: column.join(',') // Convertir el array a una lista separada por comas
    } });
  }


  getAllContacts(page: number = 1, perPage: number = 10) {
    return this.http.get(`${environment.apiUrl}/api/user/getAllContacts`, {
      params: { page: page.toString(), perPage: perPage.toString() }
    });
  }

  getChats(){
    return this.http.get(`${environment.apiUrl}/api/user/getChats`, { params: {} });
  }

  sendMessage(chat_id, message){
    return this.http.post(`${environment.apiUrl}/api/user/sendMessage`, { 'chat_id': chat_id, 'message': message });
  }

  getConversation(chat_id){
    return this.http.get(`${environment.apiUrl}/api/user/getConversation`, { params: { 'chat_id': chat_id } });
  }


  getUserCart(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/cart/getUserCart`, { params: { 'user_id': user_id } });
  }

  importExcel(data, fileName) {
    return this.http.post(`${environment.apiUrl}/api/user/importexcel`, { data: data.data, fileName: fileName})

  }

  getMails(){
    return this.http.get(`${environment.apiUrl}/api/user/fetchMails`, { params: {} });
  }


  sendEmail(mail){
    return this.http.post(`${environment.apiUrl}/api/user/sendEmail`, { mail: mail})
  }

  getMailsAccounts(){
    return this.http.get(`${environment.apiUrl}/api/user/getMailsAccounts`, { params: {} });
  }

  getUserWishlist(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/cart/getUserWishlist`, { params: { 'user_id': user_id } });
  }

  getCountries() {
    return this.http.get(`${environment.apiUrl}/api/getCountries`, { params: {} });
  }

  insert(user_id: string, lab_id: string) {
    return this.http.put(`${environment.apiUrl}/api/cart/insert`, { 'user_id': user_id, 'lab_id': lab_id });
  }

  insertFavourite(user_id: string, lab_id: string) {
    return this.http.put(`${environment.apiUrl}/api/cart/insertFavouriteList`, { 'user_id': user_id, 'lab_id': lab_id });
  }

  delete(user_id: string, lab_id) {
    return this.http.delete(`${environment.apiUrl}/api/cart/delete`, { params: { 'user_id': user_id, 'lab_id': lab_id } });
  }

  deleteFavourite(user_id: string, lab_id) {
    return this.http.delete(`${environment.apiUrl}/api/cart/deleteFavouriteList`, { params: { 'user_id': user_id, 'lab_id': lab_id } });
  }

  userLabs(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/user/userLabs`, { params: { 'user_id': user_id } });
  }

  getCustomerInfo(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/getCustomerInfo`, { params: { 'user_id': user_id } });
  }



  getCharges(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/getCharges`, { params: { 'user_id': user_id } });
  }


  sendMail(user_id: string, comment: string, type: string) {
    return this.http.get(`${environment.apiUrl}/api/report/sendMail`, { params: { 'comment': comment, 'user_id': user_id, 'type': type } });
  }

  getSearchAutoFill(nameFlyer: string){
    return this.http.get(`${environment.apiUrl}/api/flyerEditor/searchAutoFill`, { params: { 'name': nameFlyer } });
  }

  getChargeFlyerInfo(labName?, facultyName?, degreeName?, level_id?){
    return this.http.get(`${environment.apiUrl}/api/flyerEditor/chargeFlyerInfo`, { params: { 'labName': labName, 'facultyName': facultyName, 'degreeName': degreeName, 'level_id': level_id } });
  }

}
