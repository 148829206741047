import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { SessionDataService } from './session-data.service';
import { AdminService } from './admin.service';
import { UserDataService } from './user-data.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PusherService {

  pusher: any;
  channel: any;
  filtersGrammarIndex = new Array()
  phrasesFilters = new Array()
  channelChats: any;


  subscribeToChats(allChats, messageHandler: (data: any) => void) {
    this.channelChats = [];
    for (let i = 0; i < allChats.length; i++) {
      const chatId = allChats[i].id;
      console.log(`Subscribing to chat.${chatId}`);

      // Suscribirse al canal del chat
      const channel = this.pusher.subscribe(`chat.${chatId}`);
      this.channelChats.push(channel);

      // Vincular el evento al canal
      channel.bind('App\\Events\\getNewMessages', (data: any) => {
        messageHandler(data);  // Llama al handler del componente
      });
    }
  }



  getUserTrackSubject = new Subject<any>();
  getUserTrack$ = this.getUserTrackSubject.asObservable();



  constructor(private http: HttpClient, private admin: AdminService,  private userData: UserDataService ) {

    this.pusher = new Pusher('12345678A*', {
      cluster: 'mt1',
      wsPort: 443,
      wssPort: 443,
      wsHost: `${environment.wsHost}`,
      // httpHost: `${environment.wsHost}`,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      forceTLS: true,
    });

    this.channel = this.pusher.subscribe('home');

    this.channel.bind('App\\Events\\SetTestConfigContent', data => {

     data.exercises = JSON.parse(data.exercises);
     let tests = Object.keys(this.admin.campusTests)
     for (let i = 0; i < tests.length; i++){
      this.admin.campusTests[tests[i]].forEach( tests => {
        tests.exams.forEach( exam =>{
          exam.versions.forEach( version  => {
            if (version.exam_id.includes(data.exam_id) ){
              version.exercises = data.exercises;
            }
          })
        });
      })
     }
    });

    this.channel.bind('App\\Events\\trackUserActivity', data => {
      console.log('trackUserActivity', data)
      this.getUserTrackSubject.next(data);
    }
    )



    this.channel.bind('App\\Events\\SetImporterTarget', data => {
      console.log('websocketTarget', data)
      this.userData.notifyExercise({ option: 'importer_target', value: data });
    }
    )


    this.channel.bind('App\\Events\\SetImporterContent', data => {
      console.log("websocket",data)
      if (data.importer == 'Grammar'){
        this.userData.notifyExercise({ option: 'grammar_importer_data', value: data});
      } else if (data.importer == 'Phrases'){
        this.userData.notifyExercise({ option: 'phrases_importer_data', value: data });
      } else if (data.importer == 'Stax'){
        this.userData.notifyExercise({ option: 'stax_importer_data', value: data });
      }

    });
  }
}

