<div class="chat-panel">
  <div class="chat-header">{{data.contacts.nombre_de_empresa}}, {{data.contacts.telefono}}</div>
  <div #chatMessagesContainer class="chat-messages">
      <!-- <div class="message received">Hi there!</div>
      <div class="message sent">Hello, how can I help you?</div> -->
        <div class="message" [ngClass]="{'sent': msg.from == 'javier.bartus@academiageorgetown.es', 'received': msg.from == 'richard@academiageorgetown.es'}" *ngFor="let msg of data.mails">
        <strong>{{ msg.from }}</strong> <!-- Nombre del usuario -->
        <p>{{ msg.body_plain }}</p> <!-- Mensaje -->
        <small>{{ msg.date | date: 'short' }}</small> <!-- Fecha del mensaje formateada -->
        </div>
  </div>
  <div class="chat-reply">
    <button (click)="openModalSendMail()"><i class="fa-solid fa-reply"></i>REPLY</button>
  </div>

</div>
