<div class="custom-modal-content" style="height: 100%;">
  <div class="custom-modal-header" style="height: 10%;">
    <div style="display: flex; flex-direction: row; ">
    <button type="submit" class="custom-button custom-submit-button" (click)="sendEmail()"><i class="fa-solid fa-paper-plane"></i></button>
    <select style="width: 300px;" (change)="onAccountSelected($event.target.value)">
      <option *ngFor="let account of accounts" [value]="account">
        {{ account }}
      </option>
    </select>
    </div>
    <button type="button" class="custom-close-button" (click)="onClose()">✖</button>
  </div>
  <div class="custom-modal-body" style="height: 90%;">
    <form (ngSubmit)="sendEmail()" style="height: 100%;">

      <div class="custom-form-group" style="display: flex; flex-direction: row; height: 7%;">

        <input
          style="width: 20%;"
          type="text"
          id="uniqueEmailSubject"
          class="custom-input"
          [(ngModel)]="email.subject"
          name="uniqueSubject"
          placeholder="Asunto"
          required
        />
        <input
        type="email"
        id="uniqueEmailRecipient"
        class="custom-input"
        [(ngModel)]="email.recipient"
        (ngModelChange)="validateEmails($event)"
        name="uniqueRecipient"
        placeholder="Para: (separados por comas para múltiples)"
        required
      />
      <p *ngIf="invalidEmails.length > 0" class="error-message">
        Los siguientes correos son inválidos: {{ invalidEmails.join(', ') }}
      </p>

      </div>


      <!-- Editor Ngx -->
      <div class="custom-form-group" style="height: 93%;">
        <label for="uniqueEmailContent">Contenido del correo</label>
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
				</ngx-editor-menu>
        <ngx-editor
          class="custom-editor"
          [(ngModel)]="email.content"
          name="content"
          [editor]="editor"
          placeholder="Escribe tu correo aquí..."
        ></ngx-editor>
      </div>

      <!-- Footer -->

    </form>
  </div>
</div>
