<div class="form-container">
  <h2>Formulario de Empresa</h2>
  <form #empresaForm="ngForm" (ngSubmit)="onSubmit()">
    <div class="form-grid">
      <div class="form-group" *ngFor="let field of fields">
        <label [for]="field.name">{{ field.label }}</label>
        <input
          *ngIf="field.type !== 'textarea'"
          [type]="field.type"
          [id]="field.name"
          [name]="field.name"
          [(ngModel)]="formData[field.name]"
        />
        <textarea
          *ngIf="field.type === 'textarea'"
          [id]="field.name"
          [name]="field.name"
          [(ngModel)]="formData[field.name]"
        ></textarea>
      </div>


    </div>
    <div class="form-actions">
      <button type="submit" [disabled]="!empresaForm.valid">Enviar</button>
    </div>
  </form>
</div>
