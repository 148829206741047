import { Component, Injectable, OnInit, ViewChild, HostListener } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Subscription } from 'rxjs';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import Editor from "src/assets/js/ckeditor.js";


@Component({
  selector: 'app-dictionary-importer',
  templateUrl: './dictionary-importer.component.html',
  styleUrls: ['./dictionary-importer.component.css']
})
export class DictionaryImporterComponent {

  @ViewChild("myEditor") myEditor: any;
  public Editor = Editor;
  public EditorText: string = " ";

  public Config = {
    placeholder: "head word: trad_1, ..., trad_n",
    removePlugins: ['Title'],
  };

  textEditionWL;
  message = '¡GUARDADO!';

  wordStaxObjectArray;
  wordStaxObjectBack;
  successfullySave: boolean = false;

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    this.checkStax();
  }

  constructor(public admin: AdminService) { }

  checkStax() {
    this.admin.checkStax(this.EditorText, 'WL').subscribe((data: any) => {

      this.message = data['message'];
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);

      this.wordStaxObjectBack = data['back_object'];
      this.wordStaxObjectArray = data['front_object'];

      let staxContentAux = new Array();
      let title = '<h1> </h1>'
      let text: string;
      let wordObject, wordPropertyObject, propertyName;

      propertyName = 'translations'

      // array
      for (let i = 0; i < this.wordStaxObjectArray.length; i++) {
        wordObject = this.wordStaxObjectArray[i];

        if (wordObject.existed) {
          text = '<p>' + wordObject.word + ': '
        } else {
          text = '<p><mark class=\"marker-green\">' +  wordObject.word + ': '
        }
        staxContentAux.push(text);

        for (let j = 0; j < wordObject[propertyName].length; j++) {
          wordPropertyObject = wordObject[propertyName][j];

          if (wordPropertyObject.existed) {
            text = wordPropertyObject.translation + ', ';
          } else {
            text = '<mark class=\"marker-green\">' +  wordPropertyObject.translation + ', ';

          }

          staxContentAux.push(text);
        }

        // se quita la coma del final
        staxContentAux[staxContentAux.length - 1] = staxContentAux[staxContentAux.length - 1].replace(/(^[,\s]+)|([,\s]+$)/g, '');

        staxContentAux.push('</p>');
      }

      this.textEditionWL = title + staxContentAux.join('');
      this.EditorText = this.textEditionWL;

    });
  }

  setDictionaryWords() {
    this.admin.setDictionaryWords(this.EditorText, this.wordStaxObjectBack).subscribe((data: any) => {
      this.successfullySave = true;
      this.message = '¡GUARDADO!';
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });;
  }

  status

  toggleStatus() {
    if(this.status){
      this.checkDictionaryWords()
    }
  }

  words = ""
  InDict = false
  notInFreq = false
  notInDict = true

  checkDictionaryWords(){
  let listWords = this.EditorText.split(',');
    this.admin.checkDictionaryWords(listWords).subscribe((data: any) => {
      console.log(data)
      this.words = data
    });
  }




  onReady(editor) {
    console.log(this.myEditor);
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      );
  }

  onChange({ editor }: ChangeEvent) {
    this.EditorText = editor.getData();
    if (this.textEditionWL == undefined && this.EditorText != '' && this.status == false) {
      this.checkStax()
    }

    if(this.status == true && this.EditorText != '' && this.textEditionWL == undefined){
    this.checkDictionaryWords()
    }
  }
  cleanEditor(){
    this.EditorText = '';
    this.textEditionWL = undefined;
  }

}
