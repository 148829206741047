
import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import Editor from "src/assets/js/ckeditor.js";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { AdminService } from 'src/app/services/admin.service';

interface Stax {
  title: string;
  text: string;
}

@Component({
  selector: 'app-audio-lab',
  templateUrl: './audio-lab.component.html',
  styleUrls: ['./audio-lab.component.css']
})
export class AudioLabComponent {



    constructor(public admin : AdminService) { }

  public Config = {
    removePlugins: ['Title'],
  };

  @ViewChild("myEditor") myEditor: any;
  public Editor = Editor;
  public EditorText: string = ``;




  onReady(editor) {
    console.log(this.myEditor);
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      );
  }

  finalize = false;
  onChange({ editor }: ChangeEvent) {
    this.EditorText = editor.getData();
    // if (this.finalize == false ) {
    //   setTimeout(() => {
    //   this.checkReadingContent()
    //   }, 300);
    // }
  }
  audioList
  saveText() {
    let subtype = 'tutorial';
    let texts = this.separateText(this.EditorText);

    for (let i = 0; i < texts.length; i++) {
      setTimeout(() => {
        // Crear una lista con un solo elemento
        let serializedText = JSON.stringify([texts[i]]);

        this.admin.getAudio(serializedText, subtype).subscribe((data: any) => {
          console.log(data);
          this.audioList = data;

          // Descargar automáticamente cada audio
          data.forEach(audio => {
            this.downloadAudio(audio);
          });
        });
      }, i * 10000); // Retraso de 10 segundos por petición
    }
  }
downloadAudio(audios) {
  const base64Data = audios.audio.replace(/^data:audio\/mp3;base64,/, '');
  const blob = this.base64toBlob(base64Data, 'audio/mp3');
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${audios.word}.mp3`;
  document.body.appendChild(a);
  a.click();

  // Limpiar
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

player
// playAudio(audios){
//   this.player = new Audio();
//   this.player.src = 'data:audio/mp3;base64,' + audios.audio;
//   this.player.load();
//   this.player.play();

// }

base64toBlob(base64Data: string, contentType: string): Blob {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}







  // Función para separar el texto en objetos
  separateText(text: string): Stax[] {
    const staxList: Stax[] = [];
    const regex = /#([^%]+)/g; // Expresión regular para encontrar títulos
    let match;


    const regex2 = /\$([^$]+)/g; // Expresión regular para encontrar todos los $

    while ((match = regex2.exec(text)) !== null) {
      console.log('match', match)
      const title = match[1].trim();
      const content = match[1].trim();
      console.log(content)
      console.log(title)
      const stax: Stax = {
        title: title,
        text: content.trim()
      };
      staxList.push(stax);
    }


    // Iterar sobre los resultados de la expresión regular
    while ((match = regex.exec(text)) !== null) {
      const title = match[1].trim();
      const startIndex = match.index + match[0].length;
      const endIndex = text.indexOf("#", startIndex); // Encuentra el próximo título
      const content = endIndex !== -1 ? text.substring(startIndex + 3, endIndex) : text.substring(startIndex + 3); // Contenido entre dos títulos
      const stax: Stax = {
        title: title,
        text: content.trim()
      };
      staxList.push(stax);
    }
    console.log(staxList)
    return staxList;
  }

}
