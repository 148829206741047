import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelReaderService {

  constructor() { }

  /**
   * Lee un archivo Excel y devuelve los encabezados (campos) como un arreglo.
   * @param file Archivo a leer
   * @returns Promesa con los encabezados del archivo en formato string[]
   */
  getHeaders(file: File): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = (event: any) => {
        try {
          const bstr: string = event.target.result;
          const workbook: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          // Selecciona la primera hoja
          const sheetName: string = workbook.SheetNames[0];
          const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

          // Convierte la hoja a JSON y toma solo la primera fila (encabezados)
          const json: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any[][];
          const headers = Array.isArray(json[0]) ? (json[0] as string[]) : [];
          resolve(headers);
        } catch (error) {
          reject(new Error('No se pudo procesar el archivo. Asegúrate de que el formato sea válido.'));
        }
      };

      reader.onerror = (error) => {
        reject(new Error('Error al leer el archivo: ' + error));
      };

      reader.readAsBinaryString(file);
    });
  }

  /**
   * Lee un archivo Excel y devuelve los datos completos como un arreglo.
   * @param file Archivo a leer
   * @returns Promesa con los datos en formato JSON
   */
  readExcel(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = (event: any) => {
        try {
          const bstr: string = event.target.result;
          const workbook: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          // Selecciona la primera hoja
          const sheetName: string = workbook.SheetNames[0];
          const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

          // Convierte la hoja en JSON
          const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          resolve(data);
        } catch (error) {
          reject(new Error('No se pudo procesar el archivo. Asegúrate de que el formato sea válido.'));
        }
      };

      reader.onerror = (error) => {
        reject(new Error('Error al leer el archivo: ' + error));
      };

      reader.readAsBinaryString(file);
    });
  }
}
