<div *ngFor="let text of this.data.text">

<p>{{ text.title }}</p>

<p>{{ text.type }}</p>

<div *ngFor="let word of text.textByLines">
<div [innerHTML]="getFormattedText(word)"></div>
</div>


</div>
