<div style="height: 100%; width: 100%; padding: 32px;">
<div class="toolbar">
  <button style="color: white;" (click)="addRow()">
    <i class="fas fa-plus"></i>
  </button>
</div>

<table class="table table-bordered mt-3">
  <thead>
    <tr>
      <th>Nombre Completo</th>
      <th>Posición</th>
      <th>Email</th>
      <th>Autoridad</th>
      <th>Departamento</th>
      <th>Teléfono</th>
      <th>Acciones</th>
    </tr>
  </thead>
  <tbody *ngIf="rows.length > 0">
    <tr *ngFor="let row of rows; let i = index">
      <td>
        <input type="text" [(ngModel)]="row.complete_name" class="form-control" placeholder="Nombre Completo" />
      </td>
      <td>
        <select [(ngModel)]="row.position" class="form-control">
          <option *ngFor="let option of positions" [value]="option">{{ option }}</option>
        </select>
      </td>
      <td>
        <input type="email" [(ngModel)]="row.email" class="form-control" placeholder="Email" />
      </td>
      <td>
        <input type="number" [(ngModel)]="row.authority" class="form-control" min="1" max="6" />
      </td>
      <td>
        <input type="text" [(ngModel)]="row.department" class="form-control" placeholder="Departamento" />
      </td>
      <td>
        <input type="tel" [(ngModel)]="row.phone" class="form-control" placeholder="Teléfono" />
      </td>
      <td style="display: flex; flex-direction: row;">
        <button style="display: flex; flex-direction: row ; justify-content: center; align-items: center;" class="btn btn-success" (click)="saveRow(row)">
          <i class="fas fa-save"></i>
        </button>
        <button style="display: flex; flex-direction: row ; justify-content: center; align-items: center;" class="btn btn-danger" (click)="deleteRow(i)">
          <i class="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  </tbody>
  <tr *ngIf="rows.length === 0">
    <td colspan="7" class="text-center">No hay contactos</td>
  </tr>
</table>
</div>
