import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef  } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { FileService } from 'src/app/services/file.service';
import { concatMap, delay, mergeMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

@Component({
  selector: 'app-words-lab',
  templateUrl: './words-lab.component.html',
  styleUrls: ['./words-lab.component.css']
})
export class WordsLabComponent implements OnInit {
  searchTerm: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public admin: AdminService, public fileService: FileService, public dialogRef: MatDialogRef<WordsLabComponent>) { }

  ngOnInit() {

    this.callTranslations();


    console.log("DATOS RECIBIDOS: MODAL",this.data);
  }

     separateArray(completeArray){
    let i = 0;
    let array = [];

    while(i < completeArray.length){
      let j = 0;
      let array2 = [];
      while(j < 30 && i < completeArray.length){
        array2.push(completeArray[i]);
        i++;
        j++;
      }
      array.push(array2);
    }
    return array;
  }

  isTime = false;


  handleTime(){
    if(this.isTime){
      this.isTime = false;
    }
      else{
      this.isTime = true;
  }
  }


  closeDialog() {
    let numCat = 0;
    let numSubCat = 0;
    let numPublisher = 0;
    let numOrigin = 0;
    let numGlobal = 0;
    let id = ''

    let index

    if(this.data[1].subcat.subcat_id == null || this.data[1].subcat.subcat_id == undefined){

      numCat = this.filteredWords.length;
      id = this.data[1].subcat.cat_id
    }
    else{
      for(let lab of this.data[1].subcat.info.resources){
        if(lab.name == this.data[1].type){
          index = this.data[1].subcat.info.resources.indexOf(lab);

        }
      }

      numSubCat = this.filteredWords.length;
      numCat = this.data[1].subcat.info.resources[index].num_cat;
      id = this.data[1].subcat.subcat_id
      numPublisher = this.data[1].subcat.info.resources[index].num_publisher;
      numOrigin = this.data[1].subcat.info.resources[index].num_origin;
      numGlobal = this.data[1].subcat.info.resources[index].num_global;
    }

      if(this.data[1].type == 'Word Stax'){
        this.data[1].type = 'WL'
      }
      if(this.data[1].type == 'Term Stax'){
        this.data[1].type = 'TERM'
      }
      if(this.data[1].type == 'Gram Stax'){
        this.data[1].type = 'GRAM'
      }
      if(this.data[1].type == 'Idiom Stax'){
        this.data[1].type = 'IDIOM'
      }
      if(this.data[1].type == 'Academic Stax'){
        this.data[1].type = 'ACADEMIC'
      }
      if(this.data[1].type == 'Job Stax'){
        this.data[1].type = 'JOB'
      }



    let dataToSend = {
      campus_id : "2",
      filters : null,
      level: null,
      id: id,
      importer: 'Stax',
      phase: null,
      stack: {
        imports: [],
        lab_id: this.lab_id,
        num_cat: numCat,
        num_subcat: numSubCat,
        num_publisher: numGlobal,
        num_origin: numSubCat,
        num_global: numGlobal,
        stax_type: this.data[1].type,
      }
    }

    console.log("DATOS A ENVIAR", dataToSend);
    this.dialogRef.close({ value: dataToSend});


  }
  lab_id = 0;
  deleteWordArray() {

    if(this.data[1].subcat == null){
      if (confirm("¿Estás seguro de que deseas borrar todas las palabras?")) {
        this.admin.deleteLabWord(this.selectedWords, 0, 0 ,this.data[1].type).subscribe((res: any) => {
          console.log("Todas las palabras borradas:", res);
          this.lab_id = res.lab_id;
          //  Limpia todas las palabras del arreglo de datos
          this.data[0] = [];
        });
        alert("Todas las palabras borradas");
      } else {
        console.log("Borrado cancelado");
        alert("Borrado cancelado");


    }
  }
    else{


    if(this.data[1].subcat.subcat_id == null || this.data[1].subcat.subcat_id == undefined){
      if (confirm("¿Estás seguro de que deseas borrar todas las palabras?")) {
        this.admin.deleteLabWord(this.selectedWords, this.data[1].subcat.cat_id, 0 ,this.data[1].type).subscribe((res: any) => {
          console.log("Todas las palabras borradas:", res);
          this.lab_id = res.lab_id;
          //  Limpia todas las palabras del arreglo de datos
          this.data[0] = [];
        });
        alert("Todas las palabras borradas");
      } else {
        console.log("Borrado cancelado");
        alert("Borrado cancelado");
      }


    }

    else{
    if (confirm("¿Estás seguro de que deseas borrar las palabras seleccionadas?")) {
      console.log(this.selectedWords);
      this.admin.deleteLabWord(this.selectedWords, 0 ,this.data[1].subcat.subcat_id, this.data[1].type).subscribe((res: any) => {
        console.log("Palabras seleccionadas borradas:", res);
        this.lab_id = this.data[0][0].lab_id,
        // Elimina las palabras seleccionadas del arreglo de datos
        this.data[0] = this.data[0].filter(word => !this.selectedWords.includes(word.id));
        this.selectedWords = []; // Limpia la selección después de borrar
      });
      alert("Palabras seleccionadas borradas");
    } else {
      console.log("Borrado cancelado");
      alert("Borrado cancelado");
    }
  }
}
  }

  deleteAllWords() {

    if(this.data[1].subcat == null){
    if (confirm("¿Estás seguro de que deseas borrar todas las palabras?")) {
        this.admin.deleteAllWords(0,0, this.data[1].type).subscribe((res: any) => {
          console.log("Todas las palabras borradas:", res);
          // Limpia todas las palabras del arreglo de datos
          this.data[0] = [];
        });
      } else {
        console.log("Borrado cancelado");
        alert("Borrado cancelado");
      }
      alert("Todas las palabras borradas");

    }
    else{
    if(this.data[1].subcat.subcat_id == null || this.data[1].subcat.subcat_id == undefined){
      if (confirm("¿Estás seguro de que deseas borrar todas las palabras?")) {
        this.admin.deleteAllWords(this.data[1].subcat.cat_id,0, this.data[1].type).subscribe((res: any) => {
          console.log("Todas las palabras borradas:", res);
          // Limpia todas las palabras del arreglo de datos
          this.data[0] = [];
        });
      } else {
        console.log("Borrado cancelado");
        alert("Borrado cancelado");
      }
      alert("Todas las palabras borradas");
    }

    else{


    if (confirm("¿Estás seguro de que deseas borrar todas las palabras?")) {
      this.admin.deleteAllWords(0, this.data[1].subcat.subcat_id, this.data[1].type).subscribe((res: any) => {
        console.log("Todas las palabras borradas:", res);
        // Limpia todas las palabras del arreglo de datos
        this.data[0] = [];
      });
    } else {
      console.log("Borrado cancelado");
      alert("Borrado cancelado");
    }
    alert("Todas las palabras borradas");
  }
}
}



  callTranslations() {
    let separatedArray = this.separateArray(this.data[0]);

    from(separatedArray).pipe(
      mergeMap(chunk =>
        this.makeApiCall(chunk).pipe(
          // Añade un retardo de 15 segundos entre las llamadas a cada bloque
          delay(15000)
        ),
        2 // Limita a 5 solicitudes concurrentes
      )
    ).subscribe({
      next: res => {
        console.log("RESPUESTA DE TRADUCCIONES", res);
        this.updateTranslations(res);
      },
      error: err => console.error('Error en la solicitud de traducción', err),
      complete: () => console.log('Todas las traducciones completadas')
    });
  }

  makeApiCall(chunk) {
    return this.admin.getTrans(chunk);
  }

  updateTranslations(res) {
    // Asigna las traducciones a cada elemento en filteredWords
    for (let k = 0; k < this.filteredWords.length; k++) {
      for (let j = 0; j < res.length; j++) {
        if (this.filteredWords[k].id == res[j].id) {
          this.filteredWords[k].translations = res[j].translations;
        }
      }
    }
  }

  selectedWords = [];
  buttonAgroup = false;

  selectWordId(id, agroup?){

    let index = this.selectedWords.indexOf(id);
    if(index == -1){
      this.selectedWords.push(id);
    }
    else{
      this.selectedWords.splice(index, 1);
    }

    if(this.selectedWords.length > 1 && agroup == "agroup"){
      this.wordAgroup();
    }
    console.log(this.selectedWords)
  }

  wordAgroup(){
    // Encuentra las posiciones de los ids seleccionados en filteredWords
    let positions = this.selectedWords.map(id =>
      this.filteredWords.findIndex(word => word.id === id)
    );

    // Obtén las posiciones mínima y máxima
    let minPos = Math.min(...positions);
    let maxPos = Math.max(...positions);

    // Agregar todas las palabras entre minPos y maxPos a selectedWords
    for (let i = minPos; i <= maxPos; i++) {
      if (!this.selectedWords.includes(this.filteredWords[i].id)) {
        this.selectedWords.push(this.filteredWords[i].id);
      }
    }

    console.log("Selected words positions:", positions);
    console.log("Updated selected words:", this.selectedWords);
    }




  isSelected(id: number): boolean {
    return this.selectedWords.includes(id);
  }


  Downloadtrans = true;
  DownloadDate = false;


  generateTxtFile() {
    let content = "Word";
    if (this.Downloadtrans) {
        content += ",Translation";
    }
    if (this.DownloadDate) {
        content += ",Date";
    }
    content += "\n";

    content += this.filteredWords.map((word: any) => {
        let line = word.word;
        if (this.Downloadtrans) {
          if(word.translations == null || word.translations.length == 0 || word.translations == undefined || word.translations == ""){
            line += `,No translations`
          }
          else{


          for(let i = 0; i < word.translations.length; i++){
            line += `,${word.translations[i].translation}`;
          }
        }
        }
        if (this.DownloadDate) {
            line += `, (${word.day}/${word.month}/${word.year})`;
        }
        return line;
    }).join("\n") + "\n";

    const filename = `${ this.data[1].subcat.subcat_name}_${this.data[1].type}.txt`;
    this.fileService.downloadTxtFile(content, filename);
    this.copied = true;

}
  copied = false;

  onItemClick(event: MouseEvent, id: any): void {
    if (event.ctrlKey || event.metaKey) { // Ctrl key in Windows/Linux or Cmd key in macOS
      this.selectWordId(id, "agroup");
    }
    else{
      this.selectWordId(id);
    }
  }



  get filteredWords() {
    if (!this.searchTerm) {
      return this.data[0];
    }
    return this.data[0].filter((word: any) =>
      word.word.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
}
