import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators, Editor, Toolbar } from 'ngx-editor';
import { SessionDataService } from 'src/app/services/session-data.service';
import { UserDataService } from 'src/app/services/user-data.service';


@Component({
  selector: 'app-modal-send-email',
  templateUrl: './modal-send-email.component.html',
  styleUrls: ['./modal-send-email.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class ModalSendEmailComponent {
  editor!: Editor; // Editor instance





  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  email = {
    senderMail: '',
    subject: '',
    recipient: '',
    content: '',
  };
  selectedAccount: string;

  namesArray = []

  constructor(private dialogRef: MatDialogRef<ModalSendEmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public userData: UserDataService) {
      this.data.contacts.forEach((contact) => {
        if(contact.email){
          this.namesArray.push(contact.email);
        }
      })

    this.email.recipient = this.namesArray.join(', ');


  }


  ngOnInit(): void {
    this.editor = new Editor({
      content: '',
      plugins: [],
      nodeViews: {},
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
    });
    this.getMailsAccounts()

  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  onClose(): void {
    this.dialogRef.close();
  }
  invalidEmails: string[] = []; // Array para almacenar correos inválidos
  validateEmails(input: string): void {
    // Reemplazar cada espacio con una coma
    let formattedInput = input.replace(/\s+/g, ',');

    // Eliminar comas consecutivas
    formattedInput = formattedInput.replace(/,+/g, ',');

    // Eliminar comas al principio o al final
    formattedInput = formattedInput.replace(/^,|,$/g, '');

    // Actualizar el campo con la estructura corregida
    this.email.recipient = formattedInput;

    console.log('Campo formateado:', this.email.recipient);
  }

  accounts = []
  getMailsAccounts(){

    this.userData.getMailsAccounts().subscribe((data: any) => {
      this.accounts = data
    })

  }


  onAccountSelected(event){
    console.log(event)

    this.selectedAccount = event;
  }

  sendEmail(): void {


    this.email.senderMail = this.selectedAccount;
    console.log(this.email)
    if (!this.email.subject || !this.email.recipient || !this.email.content || !this.email.senderMail) {
      alert('Por favor, completa todos los campos antes de enviar.');
      return;
    }

    console.log('Enviando correo:', this.email);
    this.userData.sendEmail(this.email).subscribe((data: any) => {
      alert('Correo enviado correctamente.');
    })
    this.dialogRef.close(this.email); // Opcional: envía los datos
    //  al cerrar
  }
}
