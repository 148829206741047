import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-stax-trans-modal',
  templateUrl: './stax-trans-modal.component.html',
  styleUrls: ['./stax-trans-modal.component.css']
})
export class StaxTransModalComponent implements OnInit {

  trustedHtml: SafeHtml; // Use SafeHtml for security

  constructor(
    public dialogRef: MatDialogRef<StaxTransModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.data);
    console.log("DATA DEL MODAL", this.data);
  }
}
