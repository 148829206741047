<h2 mat-dialog-title>Importar Datos</h2>
<div mat-dialog-content>
  <p><strong>Campos coincidentes:</strong></p>
  <ul>
    <li *ngFor="let header of data.matchingHeaders">{{ header }}</li>
  </ul>

  <p><strong>Número total de filas:</strong> {{ data.totalRows }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="importData()">Importar</button>
</div>
