import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-import-data',
  templateUrl: './modal-import-data.component.html',
  styleUrls: ['./modal-import-data.component.css']
})
export class ModalImportDataComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      matchingHeaders: string[],
      totalRows: number
    },
    private dialogRef: MatDialogRef<ModalImportDataComponent>
  ) {}

  // Método para cerrar el modal
  closeDialog(): void {
    this.dialogRef.close();
  }

  // Método para confirmar la importación
  importData(): void {
    this.dialogRef.close({ imported: true });
  }
}
