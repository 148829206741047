import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ModalSendEmailComponent } from '../modal-send-email/modal-send-email.component';

@Component({
  selector: 'app-modal-conversation',
  templateUrl: './modal-conversation.component.html',
  styleUrls: ['./modal-conversation.component.css']
})
export class ModalConversationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { contacts: any; mails: any }, public dialog: MatDialog ) {}

  ngOnInit(): void {

    console.log(this.data);
  }


  openModalSendMail(){

    let contactArray = []

    contactArray.push(this.data.contacts)


    this.dialog.open(ModalSendEmailComponent, {
      width: '50%',
      height: '80%',
      data: {
        contacts: contactArray,
      },
    });
  }




}
