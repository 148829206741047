import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { SessionDataService } from 'src/app/services/session-data.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-modal-add-contact',
  templateUrl: './modal-add-contact.component.html',
  styleUrls: ['./modal-add-contact.component.css']
})
export class ModalAddContactComponent {

  fields = [
    { name: 'nombre_de_empresa', label: 'Nombre de la Empresa', type: 'text', required: true },
    { name: 'direccion', label: 'Dirección', type: 'text', required: true },
    { name: 'codigo_postal', label: 'Código Postal', type: 'text', required: false },
    { name: 'sub_localidad', label: 'Sub Localidad', type: 'text', required: false },
    { name: 'localidad', label: 'Localidad', type: 'text', required: true },
    { name: 'provincia', label: 'Provincia', type: 'text', required: false },
    { name: 'comunidad_autonoma', label: 'Comunidad Autónoma', type: 'text', required: false },
    { name: 'latitud', label: 'Latitud', type: 'text', required: false },
    { name: 'longitud', label: 'Longitud', type: 'text', required: false },
    { name: 'telefono', label: 'Teléfono', type: 'text', required: false },
    { name: 'tel_adicional', label: 'Teléfono Adicional', type: 'text', required: false },
    { name: 'email', label: 'Correo Electrónico', type: 'email', required: false },
    { name: 'sitio_web', label: 'Sitio Web', type: 'url', required: false },
    { name: 'actividad', label: 'Actividad', type: 'text', required: false },
    { name: 'subsector', label: 'Subsector', type: 'text', required: false },
    { name: 'forma_social', label: 'Forma Social', type: 'text', required: false },
    { name: 'facebook', label: 'Facebook', type: 'url', required: false },
    { name: 'instagram', label: 'Instagram', type: 'url', required: false },
    { name: 'twitter', label: 'Twitter', type: 'url', required: false },
    { name: 'youtube', label: 'YouTube', type: 'url', required: false },
    { name: 'coleccion', label: 'Colección', type: 'text', required: false },
  ];

  // Objeto que contendrá los datos del formulario
  formData: any = {};

  constructor(private http: HttpClient, private userData: UserDataService) {}

  // Enviar datos al backend
  onSubmit() {
    // const apiUrl = 'https://tu-backend.com/api/empresas';
    // this.http.post(apiUrl, this.formData).subscribe(
    //   (response) => {
    //     console.log('Formulario enviado exitosamente:', response);
    //     alert('Formulario enviado exitosamente');
    //   },
    //   (error) => {
    //     console.error('Error al enviar el formulario:', error);
    //     alert('Error al enviar el formulario');
    //   }
    // );

    console.log(this.formData);


  }

}
