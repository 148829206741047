import { Component, Injectable,  OnInit, ViewChild  } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserDataService } from 'src/app/services/user-data.service';
import Editor from "src/assets/js/ckeditor.js";



@Injectable({
  providedIn: 'root'
})

export class filterSet {
  filter_id = '';
  filter_name= '';
  newArea: boolean = false;
  options = new Array();
}

@Component({
  selector: 'app-phrase-importer',
  templateUrl: './phrase-importer.component.html',
  styleUrls: ['./phrase-importer.component.css']
})
export class PhraseImporterComponent implements OnInit {

  @ViewChild("myEditor") myEditor: any;
  public Editor = Editor;
  public EditorText: string = " ";

  public Config = {
    placeholder: "Type the content here!"
  };


  branch;
  levelCampus = '';
  levelName = '';
  level;
  levels = [];
  fields = [];
  topicType = [];
  textEdition;
  newFilter: boolean = false;
  updateExplanation: boolean = false;
  successfullySave: boolean = false;
  message = '¡GUARDADO!';
  searchingArea: boolean = false;
  openArea: boolean = false;
  dropDownSearch: boolean = false;
  viewText: boolean = false;
  editOptions: boolean = false;


  phrasesFilters;
  newPhrasesFilters = new Array();
  arrayFiltersSelected = new Array();
  filterSelected = new Array();

  filtersImporters = new Array();
  filterImportersSelected = [];

  addingSubarea: boolean = false;
  showFilterOptions;
  filterToRemove;
  openOptions: boolean = false;
  warningMessage: boolean = false;
  subscription: Subscription;

  constructor(public admin: AdminService,  private userData: UserDataService) { }

  ngOnInit(): void {
    this.getPhrasesFilters();
    this.getAllImportsFiltersNames();

    this.subscription = this.userData.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'phrases_importer_data') {
        this.setPusherData(res.value);
      }
    });
  }

  onReady(editor) {
    console.log(this.myEditor);
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  onChange({ editor }: ChangeEvent) {
    this.textEdition = editor.getData();
  }


  cleanTextEditor(){
    this.EditorText = '';
    this.textEdition =  this.EditorText;
    this.message = null;
  }

  arrayIndexPhrases = new Array()
  getPhrasesFilters() {
  this.admin.getPhrasesFilters().subscribe((data: any) => {
      this.phrasesFilters = data[0];
      let levelCampus;
      for (let indexData = 0; indexData < data[0].length; indexData++){
        if (indexData == 0){
          levelCampus = 'levels';
        } else if (indexData == 1){
          levelCampus = 'subject';
        }
        this.phrasesFilters[indexData][levelCampus].forEach( (level, i)  =>{
          this.arrayIndexPhrases = new Array();
          if (indexData == 0){
            level.name = this.courseName(i);
          }
          if (level.phrases != undefined){
            level.phrases.forEach( phrase =>{
              phrase.childs = new Array();
            })
            let phrasesAux = level.phrases;
            let indexAux = level.phrases.length -1;
            while(indexAux >= 0 && phrasesAux[indexAux].parent != null){
              let indexParent = phrasesAux[indexAux].parent;
              phrasesAux[indexParent].childs.push(phrasesAux[indexAux])
              indexAux--;
            }
            phrasesAux = phrasesAux.filter( phrase => phrase.parent == null);

            let childsAux = phrasesAux;
            let indexChild = 0;
            this.indexPhrases(childsAux, indexChild)
            level.phrases = this.arrayIndexPhrases;
            level.phrases.forEach( phrase =>{
              phrase.childs = new Array();
            })
          }

        });
      }

      for (let i = 0; i <  data[1].length; i++){
        this.filterSelected[i] = new Array()
        let filterAux = new filterSet();
        filterAux.filter_id = data[1][i].filter_id;
        filterAux.filter_name = data[1][i].filter_name;
        filterAux.newArea = false;
        if (data[1][i].options.length != 0){
          filterAux.options = data[1][i].options
        }
        this.newPhrasesFilters.push(filterAux);
      }
    });
  }


  indexPhrases(phrases, indexChild){
    phrases.forEach(phrase =>{
      phrase.indexChild = indexChild;
      this.arrayIndexPhrases.push(phrase);
      if (phrase.childs.length > 0){
        indexChild++;
        this.indexPhrases(phrase.childs, indexChild);
      }
    })

  }

  courseName(level) {
    let levelName = '';
    switch (level) {
      case 0:
        levelName = 'A1';
        break;

      case 1:
        levelName = 'A2 - KET';
        break;

      case 2:
       levelName = 'B1 - PET';
        break;

      case 3:
       levelName = 'B2 - FCE';
        break;

      case 4:
       levelName = 'C1 - CAE';
        break;

      case 5:
      levelName = 'C2 - CPE';
        break;

      default:
        break;
    }
    return levelName;
  }

  setPusherData(data?){
    let campus =  data.campus_id;
    let levelCampus;
    if (campus == 0){ //nivel
      levelCampus = 'levels';
    } else if (campus == 1){
      levelCampus = 'subject'
    }
    let phrases = data.phrases;
    let id = data.id; //id de subtipos en gramática dentro de áreas
      this.phrasesFilters[campus][levelCampus].forEach(level => {
        if (level.id == id){
            level.phrases = phrases;
            level.phrases.forEach( phrase =>{
              phrase.childs = new Array();
            })
            let phrasesAux = level.phrases;
            let indexAux = level.phrases.length -1;
            while(indexAux >= 0 && phrasesAux[indexAux].parent != null){
              let indexParent = phrasesAux[indexAux].parent;
              phrasesAux[indexParent].childs.push(phrasesAux[indexAux])
              indexAux--;
            }
            phrasesAux = phrasesAux.filter( phrase => phrase.parent == null);

            let childsAux = phrasesAux;
            let indexChild = 0;
            this.indexPhrases(childsAux, indexChild)
            level.phrases = this.arrayIndexPhrases;
            level.phrases.forEach( phrase =>{
              phrase.childs = new Array();
            })
        }
      });
  }


  setIndexPhrases(phrase){
  if ( phrase.parent != null){
    phrase = this.level.phrases[phrase.parent]
  }
    return phrase;
  }



  getAllImportsFiltersNames(){
    this.admin.getAllImportsFiltersNames('Phrases').subscribe((data: any) => {
      this.filtersImporters = data;
    });
  }

  includeFilter(filter){
    let filterAux = {
      filter_id:  filter['filter_id'],
      filter_name:  filter['filter_name'],
      newArea: false,
      options: filter['options']
    }

    let foundFilterId =  this.newPhrasesFilters.find( filter => filter.filter_id == filterAux.filter_id);
    if (foundFilterId == undefined){
      // this.newPhrasesFilters.push(filterAux);
      this.addNewFilter(filterAux.filter_name, filterAux.options)
    }
  }


  phraseExplanationSelected;
  getPhrases(phrase?) {
    this.phraseExplanationSelected = phrase;
    let options = [];
    this.arrayFiltersSelected.forEach(filter => {
      if (filter.length != 0){
        options.push(filter)
      }
    });
    let textExplanation = new Array();
    this.admin.getPhrases(options).subscribe(data => {
      let phrasesContent = JSON.parse(JSON.stringify(data));
      phrasesContent.forEach(  phrase =>{
        textExplanation.push(phrase.text)
      });
      this.EditorText = textExplanation.join(" ");
      this.textEdition = String(this.EditorText);
    });

  }

  selectBranch(){
    this.phrasesFilters.map((branch) => {branch.selected = false; return branch;});
    if (this.branch == 0){
      this.levelCampus = 'levels';
    } else if (this.branch == 1){
      this.levelCampus = 'subject' ;
    }
  }

  selectLevel(index){
    if (index == 'All'){
      this.phrasesFilters[this.branch][this.levelCampus].map((level) =>{ level.selected = true; return level;});
    }
  }


  selectFilterOptions(index2, index){
    if (this.arrayFiltersSelected.length > 0 ){
      this.arrayFiltersSelected = this.arrayFiltersSelected.filter(filter => filter.filter_id != this.newPhrasesFilters[index].filter_id);
    }
    let filterOption = {
      filter_name: this.newPhrasesFilters[index].filter_name,
      filter_id:  this.newPhrasesFilters[index].filter_id,
      option_name: this.newPhrasesFilters[index].options[index2].option_name,
      option_id:  this.newPhrasesFilters[index].options[index2].option_id,
    }
    this.newPhrasesFilters[index].selected = true;
    this.arrayFiltersSelected.push(filterOption)
    return;
  }

  selectFiltersFromIndex(option){
    this.newPhrasesFilters.forEach( (filter, index) => {
     filter.options.find( (opt, index2) => {
       if (opt.option_id == option.option_id){
        this.filterSelected[index] = opt;
        this.filterSelected[index].filter_id = filter.filter_id;
        this.filterSelected[index].filter_name = filter.filter_name;
        this.selectFilterOptions(index2, index);
        return
       }
     });
    });
  }


  removeFilterSelection(filter){
    this.arrayFiltersSelected = this.arrayFiltersSelected.filter(flt => flt.option_id != filter.option_id);
    this.filterSelected.forEach((flt, i) => {
      if(flt.option_id == filter.option_id){
        this.filterSelected[i] = new Array();
      }
    });
  }


  setIndexOptions(option){
    if (this.arrayFiltersSelected.length > 0){
      let found = this.arrayFiltersSelected.find( filter => filter.option_id == option.option_id);
      if (found != undefined) {
        this.openArea = true;
        return true;
      }
    }
  }

  newSubArea(event, index, filter?) {
    if (event.target.value == "New" && index == undefined) {
      this.addingSubarea = true;
    } else if (event.target.value == "New" && index != undefined){
      this.newPhrasesFilters[index].newArea = true;
    }  else if (event.target.value == "Remove"){
      this.filterToRemove = filter;
      this.warningMessage = true;
    }  else if (event.target.value != "New"){
      this.filterSelected[index].filter_id = this.newPhrasesFilters[index].filter_id;
      this.filterSelected[index].filter_name = this.newPhrasesFilters[index].filter_name;
      this.selectFilterOptions(event.target.selectedIndex-1, index);
    }
  }

  saveSubArea(value) {
    this.addingSubarea = false;
    var levels = new Array();
    levels.push(this.level);
    this.admin.addGrammarIndexSubarea(levels, this.phrasesFilters[this.branch][this.levelCampus], value).subscribe((data) => {
      this.phrasesFilters[this.branch][this.levelCampus].push(data);
    });
  }


  addNewFilter(value, filterOptions?){
    this.newFilter = false;
    let filterName = new Array();
    filterName.push(value);
    this.admin.addFilter(filterName, 'Phrases').subscribe((data) => {
      let filter = {
        filter_id : data[0].id,
        filter_name:   data[0].name,
        newArea: false,
        options: filterOptions
      }
      this.newPhrasesFilters.push(filter);
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

  closeWarningMessage(){
    this.filterSelected.forEach( (filter , i)=> {
      if (filter == 'Remove'){
        this.filterSelected[i] = new Array();
        this.warningMessage = false;
      }
    })
  }

  removeFilter(){
    this.admin.removeFilter(this.filterToRemove.filter_id, 'Phrases').subscribe((data) => {
      this.message = JSON.parse(JSON.stringify(data));
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
      this.newPhrasesFilters.forEach((filter, i) => {
        if (this.filterToRemove.filter_id == filter.filter_id){
          this.newPhrasesFilters.splice(i, 1);
        }
      })
      this.closeWarningMessage()
    });
  }

  addNewFilterOptions(value, filter ){
    filter.newArea = false;
    let filter_options= new Array();
    filter_options.push(value);
    this.levels.push(this.level);
    let options_ids = [];
    this.arrayFiltersSelected.forEach(filter => {
      if (filter.option_id != undefined){
        options_ids.push(filter.option_id)
      }
    });
    this.admin.addFiltersOption(filter.filter_id, filter_options, 'Phrases',  this.levels, this.topicType['id'], options_ids).subscribe((data) => {
      filter.options.push(data);
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

  editFiltersOptions(value, option){

    option.option_name = value;
    this.editOptions = false;
    this.admin.updateFiltersOptions(option.option_id, option.option_name).subscribe((data) => {
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }



  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.newPhrasesFilters, event.previousIndex, event.currentIndex);
  }

  setPhrasesConfig() {

    let phrases = new Array();
    let new_phrases = new Array();
    let levels = new Array();
    let subjects = new Array();
    let options = [];

    phrases.push(this.textEdition);

    this.arrayFiltersSelected.forEach(filter => {
        options.push(filter)
    });

    if(this.levelCampus == 'levels'){
      levels.push(this.level)
    } else if (this.levelCampus == 'subject'){
      subjects.push(this.level)
    }
    if (this.updateExplanation == false){
      this.admin.setPhrasesConfig(phrases, levels, subjects, options).subscribe((data: any) => {
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500)
      });

    } else{
      this.admin.updatePhrases(phrases, levels, subjects, options).subscribe((data: any) => {
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500)
      });
    }
  }


  searchPhrasesArea(search_value){
    this.searchingArea = true;
    search_value = search_value.toLowerCase();
    let searchAreaAux = new Array();
    if (search_value.length > 2) {
      if (this.level.phrases != undefined){
        this.level.phrases.forEach((phrase, i) => {
            if (phrase.option_name.toLowerCase().indexOf(search_value) != -1){
              this.dropDownSearch = true;
              phrase.found = true;
            } else{
              phrase.found = false;
            }
        });
      }
    } else{
      this.dropDownSearch = false
    }
  }

  closeContent(){
    this.admin.closeContent('Phrases', this.phraseExplanationSelected.phrase_bubble_id).subscribe((data) => {
      this.successfullySave = true;
      this.message = 'ARCHIVADO';
      this.cleanTextEditor()
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }


}
