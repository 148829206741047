
<div *ngIf="this.moveToStax == false" class="position-relative h-100 w-100">
    <div *ngIf="successfullySave == true && messageArray.length == 0" >
        <div class="saveMessage"> {{message}} </div>
    </div>
    <div  class="saveMessageArrayPos"  *ngIf="successfullySave == true && messageArray.length != 0" >
        <div class="saveMessageArray d-flex" style="flex-direction: column;" *ngFor="let message of messageArray"> {{message}} </div>
    </div>
    <div class="h-100 d-flex w-100" *ngIf="coursesCatTree != undefined">
      <div class="h-100 d-flex" style="width: 33%; flex-direction: column;">
        <div class="blue-bg w-100" style="height: 54%; margin: 1%; padding: 1.5%;" *ngIf="learningPathInfo != undefined">
          <i class="fa-solid fa-xmark" style="cursor: pointer;" (click)="learningPathInfo = undefined"></i>
          <h1> LEARNING PATH </h1>
            <div class="d-flex w-100" >
                <div class="col-md-3 p-0">
                    <h1>
                        RESOURCES
                    </h1>
                    <div class="light-blue ex-box d-flex"  *ngFor="let resource of learningPathInfo.resources" [ngClass]="{'done': resource.available == true}">
                        <div>
                            {{resource.name}}
                        </div>
                        <div class="d-flex" style="margin-left: auto;">
                            <span class="yellow-color"> {{resource.num_subcat}} </span>
                            -
                            <span class="yellow-color"> {{resource.num_cat}} </span>
                            -
                            <span> {{resource.num_publisher}} </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-0">
                    <h1 style="margin-bottom: 0;">
                        SKILLS
                    </h1>
                    <div class="d-flex w-100">
                        <div class="w-100 h-100">
                            <div class="light-blue white-color ex-box" *ngFor="let skill of learningPathInfo.skills" [ngStyle]="{'display': skill.column == 0 ? 'flex': 'none' }" [ngClass]="{'done': skill.available == true}">
                                <div>
                                    {{skill.name}}
                                </div>
                                <div class="d-flex" style="margin-left: auto;">
                                    <span class="yellow-color"> {{skill.num_subcat}}  </span>
                                    -
                                    <span class="yellow-color"> {{skill.num_cat}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="w-100">
                            <div class="light-blue white-color ex-box"  *ngFor="let skill of learningPathInfo.skills" [ngStyle]="{'display': skill.column == 1 ? 'flex': 'none'}" [ngClass]="{'done': skill.available == true}">
                                <div>
                                    {{skill.name}}
                                </div>
                                <div class="d-flex"  style="margin-left: auto;">
                                    <span class="yellow-color"> {{skill.num_subcat}}  </span>
                                    -
                                    <span class="yellow-color"> {{skill.num_cat}} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 p-0">
                    <h1>
                        PREMIUM SERVICES
                    </h1>
                    <div class="light-blue white-color ex-box">
                        Job Interview
                    </div>
                    <div class="light-blue white-color ex-box">
                        CV & Cover Letters
                    </div>
                    <div class="light-blue white-color ex-box">
                        Professional development
                    </div>
                    <div class="light-blue white-color ex-box">
                        Speaking Assessment
                    </div>
                    <div class="light-blue white-color ex-box">
                        Video CV
                    </div>
                    <div class="light-blue white-color ex-box">
                        Speaking training
                    </div>
                </div>
            </div>
        </div>
        <div [ngStyle]="{'height': learningPathInfo ? '42%' : '95%'}"  style="margin-top: 3%; padding: 0 1.5%;" *ngIf="courses != undefined && coursesMode == true" >
            <div style="color: gray; font-weight: 500; margin: auto; flex-direction: column;" class="d-flex">
                <div class="selectorMode" style="display: flex; flex-direction: row; justify-content: space-between;">
                    <span>ALTEA COURSES</span>
                    <span (click)="selectMode('cluster')">Clusters?</span>

                  </div>
                <div class="searchBox d-flex" style="width: 100%; margin-left: auto;" (click)="searchingStudent = true" (keyup)="searchGroup($event.target.value);"  [appTooltip]="54">
                    <i *ngIf="searchingStudent == false" class="fa-solid fa-magnifying-glass my-auto"></i>
                    <input type="text" class="w-100" style="outline: none; border: none;">
                    <i class="fa-solid fa-arrow-up-wide-short" style="cursor: pointer;" [ngStyle]="{color: ordedBy == 'high' ? 'green' : 'black'}" (click)="orderCourses('high')"></i>
                    <i class="fa-solid fa-arrow-down-wide-short" style="cursor: pointer;" [ngStyle]="{color: ordedBy == 'low' ? 'green' : 'black'}" (click)="orderCourses('low')"></i>
                </div>
            </div>
            <div class="d-flex" style="width: 100%; height: 90%;">
                <div style="width: 100%; flex-direction: column;  display: flex; padding: 1.5% 0; overflow-y: scroll;">
                    <div *ngFor="let course of courses; let l = index" class=" course d-flex justify-content-between"
                     [ngClass]="{'assignCategory': course == assignCourse, 'hasCourse': course.subcatsCount != 0}">
                     <div (click)="selectCourse(course)">
                     {{course.course_name | titlecase }}
                      </div>

                      <!-- Pasar subcats a mix y que sea un tipo inventario de course al que se le pueda dar checks y tal -->
                      <!-- el click de abajo activa el ngif de arriba -->
                      <span style="margin-right: 3%;" (click)="showSubCatsRelated = course">{{course.subcatsCount}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div [ngStyle]="{'height': learningPathInfo ? '42%' : '95%'}"  style="margin-top: 3%; padding: 0 1.5%;" *ngIf="courses != undefined && clusterMode == true" >
          <div style="color: gray; font-weight: 500; margin: auto; flex-direction: column;" class="d-flex">
            <div class="selectorMode" style="display: flex; flex-direction: row; justify-content: space-between;">
                  <span>CLUSTERS</span>
                  <span (click)="selectMode('courses')">Courses Tesla?</span>
              </div>
              <div class="searchBox d-flex" style="width: 100%; margin-left: auto;" (click)="searchingStudent = true" (keyup)="searchGroup($event.target.value);"  [appTooltip]="54">
                  <i *ngIf="searchingStudent == false" class="fa-solid fa-magnifying-glass my-auto"></i>
                  <input type="text" class="w-100" style="outline: none; border: none;">
                  <i class="fa-solid fa-arrow-up-wide-short" style="cursor: pointer;" [ngStyle]="{color: ordedBy == 'high' ? 'green' : 'black'}" (click)="orderCourses('high')"></i>
                  <i class="fa-solid fa-arrow-down-wide-short" style="cursor: pointer;" [ngStyle]="{color: ordedBy == 'low' ? 'green' : 'black'}" (click)="orderCourses('low')"></i>
              </div>
          </div>
          <div class="d-flex" style="width: 100%; height: 90%;">
              <div style="width: 100%; flex-direction: column;  display: flex; padding: 1.5% 0; overflow-y: scroll;">
                  <div *ngFor="let cluster of clusters; let l = index" class=" course d-flex justify-content-between"
                   [ngClass]="{'hasCourse': cluster.subcategories.length != 0}">
                   <div (click)="selectCluster(cluster)">
                   {{cluster.cluster_name | titlecase }}
                    </div>

                    <!-- Pasar subcats a mix y que sea un tipo inventario de course al que se le pueda dar checks y tal -->
                    <!-- el click de abajo activa el ngif de arriba -->
                    <span style="margin-right: 3%;">{{cluster.subcategories.length}}</span>
                  </div>
                  <div class="newCat d-flex">
                    <input type="text" class="w-100" style="outline: none; border: none;" [(ngModel)]="newSubcatName" (keyup.enter)="newCatSubcat('cluster', newSubcatName)">
                    <div style="cursor: pointer" (click)="newCatSubcat('cluster', newSubcatName)" placeholer="Nuevo Cluster">
                        +
                    </div>
                </div>
              </div>
          </div>
      </div>
    </div>

    <div style="width: 33%;">

      <div class="toggle-container">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="sync">
          <span style="color: white !important; font-weight: 300 !important;" class="slider round">{{ sync ? 'Sync' : 'Async' }}</span>
        </label>
      </div>


    <span (click)="selectMode('cluster')">Clusters?</span>

    <div style="border: 4px solid #A8143E; margin: 10px; height: 81%;" class="mix">

      <h1 style="text-align: center; color: black;">Links</h1>
      <div class="save_relation" *ngIf="coursesMode" (click)="assignCourseToSubcategory();">
        Guardar
      </div>
      <div class="save_relation" *ngIf="clusterMode" (click)="assignClusterToSubcategory();">
        Guardar
      </div>

      <div>
      <div *ngIf="confirmRelation == true"  style="color: grey;">
        <div style="height: 12%; justify-content: flex-end;" class="d-flex w-100" (click)="confirmRelation = false">
            <i class="fas fa-times my-auto delete" style="font-size: 1.5vh; margin: 1.8%; cursor: pointer;"></i>
        </div>
        <div style="height: 63%; justify-content: center; align-items: center; flex-direction: column;" class="w-100 d-flex">
           <span style="margin: 0 3%;" *ngIf="assignCourse != undefined && coursesMode == true">
               {{assignCourse.course_name  | titlecase }}
           </span>
           <span style="margin: 0 3%;" *ngIf="assignCluster != undefined; clusterMode">
            {{assignCluster.cluster_name  | titlecase }}
        </span>
            <i class="fa-solid fa-angle-down fas"  ></i>
            <div  style="margin: 0 3%;" *ngIf="subcategory != undefined">
                {{subcategory.subcat_name  | titlecase }}
            </div>
            <div *ngIf="checkedSubcats != 0" style="color: grey; cursor: pointer; text-align: inherit!important; width: 90%; flex-direction: column;" class="d-flex" >
                <div *ngFor="let subcat of checkedSubcats; let j = index" style="color: grey; flex-direction: row;" class="d-flex">
                  <input type="checkbox" [checked]="subcat.checked" (click)="setSearchedSubcats(subcat, $event)">
                  <span>{{subcat.subcat_name | titlecase}}</span>
                </div>
            </div>
          </div>
        </div>
    </div>

    </div>



  </div>



        <div class="h-100" style="flex-direction: column; display: flex; position: relative;overflow-y: scroll; width: 30%; padding: 1.2%;"  >
            <div class="w-100 d-flex" style="justify-content: flex-end; height: 3%;">
                <!-- <div class="deleteButton text-center" style="margin-right: 3%;" [ngClass]="{'selectedButton': changeOrder == true}" (click)="changeOrder = !changeOrder">
                    Reordenar
                </div> -->
                <div (click)="handleStateClick('Live')" [ngClass]="{'actived': isLive, 'inactive': !isLive}" class="iconArchive Live">
                <a> S </a>
                </div>
                <div (click)="handleStateClick('Beta')" [ngClass]="{'actived': isBeta, 'inactive': !isBeta}" class="iconArchive Beta">
                <a> S </a>
                </div>
                <div (click)="handleStateClick('Archived')" [ngClass]="{'actived': isArchived, 'inactive': !isArchived}" class="iconArchive Archived">
                <a> S </a>
                </div>
                <div style="color: gray; font-weight: 500; margin: auto; flex-direction: column;">
                  TESLA COURSES</div>
                <div (click)="openNoClusterModal()" style="cursor: pointer; padding-left:3px; margin-right: 3px; padding-right: 3px; border: 1px solid #A8143E; border-radius: 5px;">
                <img src="../../../../../assets/img/interrogation.png" style="height: 21px;">
                </div>
                <div class="deleteButton text-center" [ngClass]="{'selectedButton': deleteCats == true}"  (click)="deleteCats = !deleteCats;">
                    Delete
                </div>
            </div>

            <div class="searchBox d-flex position-relative" (clickOutside)="dropDownSearch = false">

                <i class="fa-solid fa-magnifying-glass my-auto"></i>
                <input type="text" class="w-100" style="outline: none; border: none;" [(ngModel)]="subcatWord" (keyup)="searchSubcat($event.target.value)" (click)="dropDownSearch = true">
                <div style="height: max-content; flex-direction: column" class="d-flex dropDownSearch" *ngIf="dropDownSearch == true"  [appTooltip]="924">
                    <div *ngFor="let subcat of searchedSubcats; let i = index" (mouseenter)="showCourseRelated = subcat" (mouseout)="showCourseRelated = undefined" style="cursor: pointer; color: grey;" class="dropDownOptions d-flex">
                        <input type="checkbox" (click)="setSearchedSubcats(subcat, $event)" [checked]="subcat.checked">
                        <span [ngClass]="{'courseActive':  subcat.course_id != null}" style="padding: 0 6%;">
                            {{subcat.subcat_name}}
                        </span>
                        <div *ngIf="showCourseRelated != undefined && showCourseRelated == subcat && showCourseRelated.course_id != null" style="position: fixed; z-index: 99; left: 75%; background-color: white;">
                            <i class="fa-solid fa-angle-right fas my-auto mx-auto" ></i>
                            <span>
                                {{subcat.course_name | titlecase}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let course of coursesCatTree; let j = index" cdkDropList
            [cdkDropListData]="course"
            [cdkDropListConnectedTo]="dropLists"
            (cdkDropListDropped)="dropSubcatOnCat($event)"
            [id]="'course-list-' + j" class="w-100" style="display: flex; flex-direction: column; position: relative;">
              <!-- Categoría que puede recibir subcategorías (drop zone) -->
              <div
                style="margin: 0;"
                class="course d-flex w-100"
                [ngClass]="{'courseActive': courseName == course.cat_name}"
                (click)="openCatMacro(course)"
              >
                <i class="fas fa-times px-1 my-auto delete" style="font-size: 1.2vh;" *ngIf="deleteCats == true" (click)="deleteCatSubcat('cat', course, j)"></i>
                <a *ngIf="editCat == false || updateCat.cat_id != course.cat_id"  (dblclick)="editCatSubcat('cat', course)">{{ course.cat_name }}</a>
                <input  *ngIf="editCat == true && updateCat.cat_id == course.cat_id" type="text" class="w-100" style="outline: none; border: none;" value="{{course.cat_name}}" (clickOutside)="editCat = false"  (keyup.enter)="updateCatSubcat('cat', $event.target.value)">
                <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" *ngIf="course.subcats != undefined" (click)="getCatTreeCategories($event, course, course.subcats)"></i>
              </div>
              <!-- cdkDropList (cdkDropListDropped)="drop($event)" -->
              <div class="h-100 d-flex" style="width: 100%;"sq *ngIf="courseName == course.cat_name && showSubcats">
                <div style="width: 100%; flex-direction: column; display: flex; padding: 1.5% 6%; padding-right: 0;" >
                  <!-- Subcategorías arrastrables -->
                  <div *ngFor="let subcat of categoriesCatTree; let k = index" cdkDrag [cdkDragData]="subcat"  class="categories d-flex w-100" style="display: flex;" [ngClass]="{'assignCategory': subcat == subcategory}">
                    <div [ngClass]="{
                          'hasCourse': subcat.subcat_state == 'Live',
                          'Archived': subcat.subcat_state == 'Archived' || subcat.subcat_state == null,
                          'Disabled': !applyFilters(subcat.subcat_state),
                          'beta': subcat.subcat_state == 'Beta',
                          'subcategorySelected': subcategory == subcat
                        }"
                        class="w-100"
                        style="display: flex; flex-direction: row; justify-content: space-between;"
                    >
                      <i class="fas fa-times px-1 my-auto delete" style="font-size: 1.2vh;" *ngIf="deleteCats == true" (click)="deleteCatSubcat('subcat', subcat, k)"></i>
                      <div>
                        <input type="checkbox" (click)="setSearchedSubcats(subcat, $event)" [checked]="subcat.checked">
                        <a [ngClass]="{'coincidence': subcat.coincidido}" (mouseenter)="showCourseRelated = subcat" (mouseout)="showCourseRelated = undefined" *ngIf="editSubcat == false || updateCat.subcat_id != subcat.subcat_id" (click)="selectSubcat(subcat)">{{subcat.subcat_name}}</a>
                      </div>
                      <div>
                        <i class="fa-solid fa-pencil" (click)="editCatSubcat('subcat', subcat)"></i>
                        <i class="fa-regular fa-trash-can" (click)="disableSubcat(subcat)"></i>
                      </div>
                      <input #editSubCatName *ngIf="editSubcat == true && updateCat.subcat_id == subcat.subcat_id" type="text" class="w-100" style="outline: none; border: none;" value="{{subcat.subcat_name}}" (keyup.enter)="updateCatSubcat('subcat', $event.target.value)">
                    </div>
                    <!-- Mostrar información adicional -->
                    <div *ngIf="showCourseRelated != undefined && showCourseRelated == subcat && showCourseRelated.course_id != null" style="position: fixed; z-index: 99; left: 39%; background-color: white;">
                      <i class="fa-solid fa-angle-right fas my-auto mx-auto"></i>
                      <span>
                        {{subcat.course_name | titlecase}}
                      </span>
                    </div>
                  </div>
                  <!-- Input para agregar una nueva subcategoría -->
                  <div class="newCat d-flex">
                    <input type="text" class="w-100" style="outline: none; border: none;" [(ngModel)]="newSubcatName" (keyup)="compareSubcats(newSubcatName)" (keyup.enter)="newCatSubcat('subcat', newSubcatName, course)">
                    <div style="cursor: pointer" (click)="newCatSubcat('subcat', newSubcatName, course)" placeholer="Nueva subcategoría">+</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="newCat d-flex">
                <input type="text" class="w-100" style="outline: none; border: none;" [(ngModel)]="newCatName"  (keyup.enter)="newCatSubcat('cat', newCatName)" >
                <div style="cursor: pointer" (click)="newCatSubcat('cat', newCatName)" placeholer="Nueva categoría">
                    +
                </div>
            </div>
        </div>

    </div>
    <div id="macroPanel" *ngIf="openMacroPanel" style="padding: 0.6rem;" class="macro-panel">
      <!-- <div style="position: absolute; right: 0; top: 0; height: 40px; width: 40px; background-color: red;">
        <i class="fa-solid fa-circle-nodes" style="color: white; height: 100%; width: 100%;"></i>
      </div> -->

      <ul class="nav nav-tabs" style="width: 100%">
        <li *ngFor="let course of coursesCatTree" class="nav-item" style="width: 15%;">

          <a class="nav-link active" style="font-size: 0.9rem; cursor: pointer; display: flex; flex-direction: row;" aria-current="page" [class.active]="selectedCatMacro == course.cat_id" (click)="openCatMacro(course)">
            <div style="height: 21px; width: 20px; background-color: greenyellow;" *ngIf="isCatTop6(course)">

            </div>
            {{course.cat_name}}
            <i *ngIf="isTargetedCat(course.cat_id)" style="color: limegreen;" class="fa-solid fa-circle"></i>
          </a>
        </li>
      </ul>
      <div style="height: 3%; justify-content: space-between;" class="d-flex">
        <div style="width: 36%; display: contents; grid-row: 1; grid-column: 1; cursor: pointer; user-select: none;" (click)="cleanSort()">
          <span style="font-size: 1.8vh !important;">{{ macroCat.cat_name }}

          </span>
        </div>


        <ul class="nav nav-tabs" style="width: 60%">
          <li class="nav-item" style="width: 23%;">
            <a class="nav-link active" aria-current="page" [class.active]="this.selectedMacro == 'STAX'" (click)="selectMacro('STAX')">Stax</a>
          </li>
          <li class="nav-item" style="width: 23%;">
            <a class="nav-link" aria-current="page" [class.active]="this.selectedMacro == 'QUIZ'" (click)="selectMacro('QUIZ')">Quizzes</a>
          </li>
          <li class="nav-item" style="width: 23%;">
            <a class="nav-link" aria-current="page" [class.active]="this.selectedMacro == 'WR'" (click)="selectMacro('WR')">Readings</a>
          </li>
        </ul>




        <div *ngIf="this.selectedMacro == 'STAX'">
          <span class="green-color">HAND IMPORTED</span>-<span class="orange-color">SUBCAT</span>-<span class="yellow-color">CAT</span>-<span class="blue-color">PUBLISHER</span>/<span class="blue-color">GLOBAL</span>-<span class="red-color"> >2 Translations</span>
        </div>

        <div *ngIf="this.selectedMacro == 'QUIZ'">
          <span>SUBCAT-CAT</span>
        </div>

        <div *ngIf="this.selectedMacro == 'WR'">
          <span>CAT-GAP-SUBCAT</span>
        </div>

          <div style="width: 4%;">
          <span style="color: white; cursor: pointer;" (click)="closeCatMacro()"><i class="fa-regular fa-x"></i></span>
        </div>
        </div>
      <div style="color: white;" class="grid-wrapper" [style.grid-template-columns]="'auto repeat(' + macroCat.subcats[0].info.resources.length + macroCat.subcats[0].info.skills.length + 1 + ', 1fr);'">

        <div class="sticky-container">
          <!-- Contenedor para los recursos tipo 'STAX' -->
          <ng-container *ngFor="let resource of macroCat.subcats[0].info.resources; let i = index">
              <div *ngIf="selectedMacro === 'STAX' && resource.type === 'STAX'"
                   [ngStyle]="{'display': 'flex', 'justify-content': 'center'}"
                   [style.grid-column]="i + 2"
                   (click)="sortMacro(resource)">
                  <span>{{ resource.name }}</span>
              </div>
          </ng-container>

          <!-- Contenedor para las habilidades tipo 'QUIZ' -->
          <ng-container *ngFor="let skill of getSkillsName(macroCat.subcats[0].info.skillStax, 'QUIZ'); let j = index">
              <div *ngIf="selectedMacro === 'QUIZ' && skill.type === 'QUIZ'"
                   [ngStyle]="{'display': 'flex'}"
                   [style.grid-column]="calcColumnGrid('QUIZ', macroCat.subcats[0].info.skillStax, j)"

                   (click)="sortMacro(skill)">
                  <span [style.color]="skill.from_resource ? 'orange' : 'white'">{{ skill.name }}</span>
              </div>
          </ng-container>

          <!-- Contenedor para las habilidades tipo 'WR' -->
          <ng-container *ngFor="let skill of getSkillsName(macroCat.subcats[0].info.skills, 'WR'); let j = index">
              <div *ngIf="selectedMacro === 'WR' && skill.type === 'WR'"
                   [ngStyle]="{'display': 'flex'}"
                   [style.grid-column]="calcColumnGrid('WR', macroCat.subcats[0].info.skills, j)"
                   (click)="sortMacro(skill)">
                  <span [style.color]="skill.from_resource ? 'orange' : 'white'">{{ skill.name }}</span>
              </div>
          </ng-container>
      </div>

      <div *ngFor="let subcat of macroCat.subcats; let i = index;" style="display: contents; font-weight: 300;" >
        <span [ngClass]="{'hasCourseMacro': subcat.subcat_state == 'Live', 'lighter': i % 2 == 0}"
              class="grid-values align-self-center"
              (click)="selectSubcatMacro(subcat)"
        [ngStyle]="isTargetedSubcat(subcat.subcat_id) ? {'border-bottom': '2px solid white'} : {}"
        style="height: 100%; justify-content: left;"
        [style.grid-row]="i + 2">
        <div (mouseenter)="isHoveredTooltip = true" (mouseleave)="isHoveredTooltip = false" style="height: 100%; width: 20px; background-color: greenyellow;" *ngIf="isSubcatTop6(subcat)">
          <div *ngIf="isHoveredTooltip" style="position: absolute; background-color: #444444e8; color: white; padding: 5px; border-radius: 5px; height: fit-content; width: 500px; padding: 3px 21px;">
            <div style="padding-top: 3px;" *ngFor="let cluster of top3Clusters">
              <span>{{ cluster.cluster_name }} x{{cluster.count}}</span>
            </div>
          </div>
        </div>
        {{ subcat.subcat_name }}
          <!-- ({{ subcat.subcat_id }})     -->
        </span>

        <!-- Contenedor para los recursos tipo 'STAX' -->
        <ng-container  *ngFor="let resource of subcat.info.resources; let j = index">
            <div *ngIf="selectedMacro === 'STAX' && resource.type === 'STAX' "
                 class="grid-values align-self-center"
                 [ngClass]="{'highlight': highlightModification(subcat.subcat_id, 'resource',changeName(resource.name)), 'lighter': i % 2 == 0}"
                 [ngStyle]="isTargetedSubcat(subcat.subcat_id) ? {'border-bottom': '2px solid white'} : {}"
                 [style.grid-column]="j + 2">

                 <span class="gold-color data" style="user-select: none; cursor: pointer;"
                 [ngClass]="{'highlightNumber': highlightModification(subcat.subcat_id), 'lighter': i % 2 == 0}">{{ resource.plus_origin }}</span>

                 <span class="green-color data" style="user-select: none; cursor: pointer;"
                 [ngClass]="{'highlightNumber': highlightModification(subcat.subcat_id), 'lighter': i % 2 == 0}">{{ resource.origin }}</span>



                <span class="orange-color data" style="user-select: none; cursor: pointer;"
                [ngClass]="{'highlightNumber': highlightModification(subcat.subcat_id), 'lighter': i % 2 == 0}"
                 (click)="handleClick($event) ? goToImport(resource, resource.num_subcat, macroCat.subcats[i]) : getWordsByLab(subcat, resource.name) ">{{ resource.num_subcat }}</span>

                <span class="yellow-color"
                [ngClass]="{'lighter': i % 2 == 0}"
                (click)="handleClick($event) ? goToImport(resource, resource.num_cat) : getWordsByCat(macroCat, resource.name)">{{ resource.num_cat }}</span>
                <!-- <span class="blue-color" [ngClass]="{'lighter': i % 2 == 0}" *ngIf="resource.name == 'Word Stax' || resource.name == 'Term Stax'" (click)="getWordsByPublisher(subcat, resource.name)" >{{ resource.num_publisher }}</span>
                <span class="blue-color" [ngClass]="{'lighter': i % 2 == 0}" *ngIf="resource.name == 'Idiom Stax' || resource.name == 'Gram Stax' || resource.name == 'Job Stax' || resource.name == 'Academic Stax'" (click)="getWordsByGlobal(resource.name)" >{{ resource.num_global }}</span> -->


                <span class="red-color data"
                style="user-select: none; cursor: pointer;"
                (click)="getWordsByRed(subcat, resource.name)"
                [ngClass]="{'highlightNumber': highlightModification(subcat.subcat_id), 'lighter': i % 2 == 0 }">
            {{ resource.lowTrans }}
          </span>
              </div>
        </ng-container>

        <!-- Contenedor para las habilidades tipo 'QUIZ' -->
        <ng-container *ngFor="let skill of getSkills(subcat.info.skillStax, 'QUIZ'); let k = index">
            <div *ngIf="selectedMacro === 'QUIZ' && skill.type === 'QUIZ'"
                 class="grid-values align-self-center"
                 [ngClass]="{'highlight': highlightModification(subcat.subcat_id, 'skill', changeName(skill.name))}"
                 style="place-self: center !important;"
                 [style.grid-column]="calcColumnGrid('QUIZ', subcat.info.skillStax, k)">
                <span *ngIf="skill.name != 'Audio Gap ALTEA'" [ngClass]="skill.num_subcat == 0 && skill.num_cat == 0 ? 'red-color' : 'yellow-color'">{{ skill.cicles }}</span>
                <!-- <span *ngIf="skill.name != 'Audio Gap ALTEA'">-</span>
                <span *ngIf="skill.name != 'Audio Gap ALTEA'" [ngClass]="skill.num_subcat == 0 && skill.num_cat == 0 ? 'red-color' : 'yellow-color'">{{ skill.num_cat }}</span>
                <span *ngIf="skill.name == 'Audio Gap ALTEA'" [ngClass]="skill.num_publisher == 0 ? 'red-color' : 'white'">{{ skill.num_publisher }}</span> -->
            </div>
        </ng-container>

        <!-- Contenedor para las habilidades tipo 'WR' -->
        <ng-container *ngFor="let skill of getSkills(subcat.info.skills, 'WR'); let k = index">
            <div *ngIf="selectedMacro === 'WR' && skill.type === 'WR'"
                 class="grid-values align-self-center"
                 [ngClass]="{'highlight': highlightModification(subcat.subcat_id, 'skill', skill.type)}"
                 style="place-self: center !important;"
                 [style.grid-column]="calcColumnGrid('WR', subcat.info.skills, k)">
                <span *ngIf="skill.name != 'Audio Gap ALTEA'" [ngClass]="skill.num_subcat == 0 && skill.num_cat == 0 ? 'red-color' : 'yellow-color'">{{ skill.num_subcat }}</span>
                <span *ngIf="skill.name != 'Audio Gap ALTEA'">-</span>
                <span *ngIf="skill.name != 'Audio Gap ALTEA'" [ngClass]="skill.num_subcat == 0 && skill.num_cat == 0 ? 'red-color' : 'yellow-color'">{{ skill.num_cat }}</span>
                <span *ngIf="skill.name == 'Audio Gap ALTEA'" [ngClass]="skill.num_publisher == 0 ? 'red-color' : 'white'">{{ skill.num_publisher }}</span>
            </div>
        </ng-container>
      </div>

      </div>
  </div>


</div>


<app-stax-importer *ngIf="moveToStax == true"></app-stax-importer>
