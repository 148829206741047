import { Component, OnInit, ViewChild, HostListener, ElementRef, ChangeDetectorRef  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import Editor from "src/assets/js/ckeditor.js";
import { Subscription } from 'rxjs';
import { UserDataService } from 'src/app/services/user-data.service';
import { Observable } from 'rxjs';
import { time } from 'console';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { StaxTransModalComponent } from '../modals/stax-trans-modal/stax-trans-modal.component';



@Component({
  selector: 'app-stax-importer',
  templateUrl: './stax-importer.component.html',
  styleUrls: ['./stax-importer.component.css']
})
export class StaxImporterComponent implements OnInit {

  @ViewChild("myEditor") myEditor: any;
  public Editor = Editor;
  public EditorText: string = " ";
  textEdition;

  public Config = {
    placeholder: "Type the content here!",
    removePlugins: ['Title'],
  };

  showTooltip: boolean = false;
  successfullySave: boolean = false;
  viewText: boolean = false;
  staxFilters = new Array();
  branch;
  levelCampus;
  level;
  arrayIndexStax = new Array();
  staxTypes = new Array();
  stax = new Object;
  wordStaxObjectArray;
  currentStaxType;
  currentStaxLabId;
  callCheckStax: boolean = true;
  multipleLevelsStax = ['GRAM', 'IDIOM', 'JOB', 'ACADEMIC'];

  showSubcats: boolean = false;
  showLevels: boolean = false;

  subscription: Subscription;

  checkedCats: any[] = [];
  checkedSubcats: any[] = [];

  // Usa Observables o Subject para mantener actualizados los datos
  checkedCats$: Observable<any[]> = new Observable<any[]>();
  checkedSubcats$: Observable<any[]> = new Observable<any[]>();
  selectedMacroData: any;

  constructor(public admin: AdminService, private userData: UserDataService, public datePipe: DatePipe, private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getStax()
    this.subscription = this.userData.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'stax_importer_data') {
        this.setPusherData(res.value);
      }

      if(res.hasOwnProperty('option') && res.option == 'importer_target'){
        console.log(res.value)
      }

    });

    this.checkedCats$ = new Observable(observer => {
      observer.next(this.checkedCats);
    });

    this.checkedSubcats$ = new Observable(observer => {
      observer.next(this.checkedSubcats);
    });

    if(this.admin.lastSelectedMacroData != undefined){
      this.selectedMacroData = this.admin.lastSelectedMacroData;
      this.admin.lastSelectedMacroData = undefined;
      setTimeout(() => {
      this.selectSubcatFromMacro()
      }, 5000);
    }
    this.getClusters()
  }



  getFormattedText(text){
    console.log(text)
    const htmlString = Array.isArray(text) ? text.join(' ') : text;
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }




  campusTypes;
  campusName;
  stacks = new Array();
  getStax() {
    this.admin.getStaxFilters().subscribe((data: any) => {
      this.campusTypes = data[0];
      this.staxTypes = data[1];
      this.stacks = data[1][0].options;
      console.log(data[0], data[1])
      this.changeStaxNames()
    });
  }

  selectSubcatFromMacro(){
    console.log(this.selectedMacroData)
    this.campusTypes[1].subject.forEach(cat => {
      cat.subcats.forEach(subcat => {
        if(subcat.subcat_id == this.selectedMacroData.subcat.subcat_id){
          subcat.checked = true;
          subcat.cat = cat.cat_name
          this.checkedSubcats.push(subcat)
        }
      })
    })

    console.log(this.selectedMacroData.resource.name)
    if(this.selectedMacroData.resource.name == 'Word Stax'){
      this.selectedMacroData.resource.name = 'WL'
    }
    if(this.selectedMacroData.resource.name == 'Term Stax'){
      this.selectedMacroData.resource.name = 'TERM'
    }
    if(this.selectedMacroData.resource.name == 'Grammar Stax'){
      this.selectedMacroData.resource.name = 'GRAM'
    }
    if(this.selectedMacroData.resource.name == 'Idiom Stax'){
      this.selectedMacroData.resource.name = 'IDIOM'
    }
    if(this.selectedMacroData.resource.name == 'Job Stax'){
      this.selectedMacroData.resource.name = 'JOB'
    }
    if(this.selectedMacroData.resource.name == 'Academic Stax'){
      this.selectedMacroData.resource.name = 'ACADEMIC'
    }




  this.stacks.forEach(stax => {
    console.log(stax.option_name, this.selectedMacroData.resource.name)
    if(stax.option_name == this.selectedMacroData.resource.name){
      this.selectCurrentStax(stax)
    }
  })

}

  changeStaxNames() {
    this.stacks.forEach(stx => {
      switch (stx.option_name) {
        case 'WL':
          stx.name = 'Word'
          break;

        case 'Not in dict':
          stx.name = 'NID'
          break;

        default:
          stx.name = stx.option_name.replace(
            /\w\S*/g,
            function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
          );
          break;
      }

    })
  }

  levels = new Array();
  campus_id;
  getCampusLevels(campus) {
    if (this.levels != campus.levels && this.levels != campus.subject) {
      this.showLevels = true;
    } else {
      this.showLevels = !this.showLevels;
    }
    this.campus_id = campus.campus_id;
    if (campus.campus_id == 1) {
      if (this.catName != undefined || this.subcatName != undefined) {
        this.catName = undefined;
        this.subcatName = undefined;
        this.currentStaxType = undefined;
      }
      this.levels = campus.levels;
      this.levels.forEach((level, i) => {
        level.name = this.setCourseName(i);
      })
    } else {
      if (this.subcategory != undefined) {
        this.subcategory = undefined;
        this.currentStaxType = undefined;

      }
      this.levels = campus.subject;
    }

    console.log("levels",this.levels)
    this.campusName = campus.campus_name;
  }


  setCourseName(level) {
    let levelName = '';
    switch (level) {
      case 0:
        levelName = 'A1';
        break;

      case 1:
        levelName = 'A2 - KET';
        break;

      case 2:
        levelName = 'B1 - PET';
        break;

      case 3:
        levelName = 'B2 - FCE';
        break;

      case 4:
        levelName = 'C1 - CAE';
        break;

      case 5:
        levelName = 'C2 - CPE';
        break;

      default:
        break;
    }
    return levelName;
  }


  showStacks: boolean = false;
  levelName = '';
  getStacksLevel(level) {
    if (this.currentStaxType != undefined) {
      this.currentStaxType = undefined;
    }
    if (this.levelName != level.name) {
      this.showStacks = true;
    } else {
      this.showStacks = !this.showStacks;
    }
    this.subcategory = level;
    this.levelName = level.name;
  }


  subcategories = new Array();
  catName;
  showCats: boolean = false;
  getCatTreeCategories(course, subcat) {
    if (this.subcategories != subcat) {
      this.showCats = true;
    } else {
      this.showCats = !this.showCats;
    }
    this.subcategories = subcat;
    this.catName = course.cat_name;
    if (this.catImporter != undefined) {
      this.catImporter = undefined;
    }
  }


  catImporter;
  multipleSubcat = false;
  catSelected = new Array();
  selectCat(cat) {
    console.log(cat)

    if (cat != this.catImporter) {
      this.cleanTextEditor();
    }
    this.catImporter = cat;
    if (this.subcatImporter != undefined) {
      this.subcatImporter = undefined;
    }
    if (this.subcategory != undefined) {
      this.subcategory = undefined;
      this.subcatName = undefined;
    }
    if (this.catName != undefined) {
      this.catName = undefined
    }
      this.textEditionWL = undefined;
    }


  subcatName;
  subcategory;
  subcatImporter;
  subcatsSelected = new Array();
  selectSubcat(subcat) {
    if (this.currentStaxType != undefined) {
      this.currentStaxType = undefined;
    }

    if (this.subcatName != subcat.subcat_name) {
      this.showStacks = true;
    } else {
      this.showStacks = !this.showStacks;
    }

    if (this.catImporter != undefined) {
      this.catImporter = undefined;
    }

    if (this.subcategory != subcat) {
      this.cleanTextEditor();
    }
    this.subcategory = subcat;
    this.subcatImporter = subcat;
    this.subcatName = subcat.subcat_name;
    this.textEditionWL = undefined;



    if(this.subcatsSelected.includes(subcat)) {
      let index = this.subcatsSelected.indexOf(subcat);
      this.subcatsSelected.splice(index, 1);
      console.log(this.subcatsSelected)
    }
    else {
      this.subcatsSelected.push(subcat);
      console.log(this.subcatsSelected)
    }

  }




  selectAllCats(campus, event) {
    console.log(campus)
    if (event.target.checked) {
      for(let i = 0; i < campus.subject.length; i++) {
        this.checkedCats.push(campus.subject[i]);
      }
    }
    else{
      this.checkedCats = []
  }
  console.log(this.checkedCats)
  }




  selectStax(staxSelected?, callStaxContent?) {


    this.cleanTextEditor();
    console.log(staxSelected)
    this.currentStaxType = staxSelected.stax_type;
    this.EditorText = '';
    if (this.currentStaxType == 'Not in dict') {
      this.callCheckStax = true;
    }


    this.currentStaxLabId = staxSelected.lab_id;
    this.staxTypes.forEach(type => {
      type.options.forEach(stax => {
        if (stax.option_name.toLowerCase() == staxSelected.stax_type.toLowerCase() && (staxSelected.lab_id != null || staxSelected.num_subcat > 0 || staxSelected.num_cat > 0)) {
          this.stax = stax;
          this.stax['lab_id'] = JSON.parse(JSON.stringify(staxSelected.lab_id));
          this.stax['num_subcat'] = JSON.parse(JSON.stringify(staxSelected.num_subcat));
          this.stax['num_cat'] = JSON.parse(JSON.stringify(staxSelected.num_cat));
          this.stax['num_publisher'] = JSON.parse(JSON.stringify(staxSelected.num_publisher));
          if (callStaxContent) {
            this.getStaxContent();
          }
          return
        }
      })
    })
  }

  setPlaceHolder() {
    switch (this.currentStaxType) {
      case 'WL':
      case 'JOB':
        this.Config = { ...this.Config, placeholder: 'head word: trad_1, ..., trad_n' };
        break;
      case 'TERM':
        this.Config = { ...this.Config, placeholder: 'term: definition' };

        break;
      case 'GRAM':
      case 'IDIOM':
      case 'ACADEMIC':
        this.Config = { ...this.Config, placeholder: ' item &&& trad_1, ..., trad_n &&& definition &&& example &&& part of speech' };

        break;
    }
    setTimeout(() => this.showEditor = true, 0)
  }

  showEditor = true;
  selectCurrentStax(stax) {
    console.log(stax)
    // si se ha seleccionado categoría hay que coger el lab_id para que el checkStax se haga bien en el back
    if (this.subcategory != undefined && this.subcategory.campus_name == undefined) {
      let currentStaxObject = this.subcategory.stacks.find(obj => obj.stax_type == stax.option_name);
      this.currentStaxType = currentStaxObject.stax_type;
      this.currentStaxLabId = currentStaxObject.lab_id;
    } else {
      this.currentStaxType = stax.option_name;
    }
    this.showEditor = false;
    this.setPlaceHolder();
    this.setoptions();



  }

  /**
   * Previo al guardado (cuando se droppea): Se muestran en verde las palabras/traducciones que enriquecen el diccionario (entradas nuevas)
   * y en gris las que no
   */


  setoptions(){

    if(this.currentStaxType == 'WL'){
      this.checkboxes = {
        trad: true,
        def: false,
        ex: false,
        pos: false
      }
    }

    if(this.currentStaxType == 'TERM'){
      this.checkboxes = {
        trad: false,
        def: true,
        ex: false,
        pos: false
      }
    }
    if(this.currentStaxType == 'GRAM' || this.currentStaxType == 'IDIOM' ||  this.currentStaxType == 'ACADEMIC' || this.currentStaxType == 'JOB'){
      this.checkboxes = {
        trad: true,
        def: true,
        ex: true,
        pos: true
      }
    }

  }

  getLabFromCat(cat, type) {
    return new Promise((resolve, reject) => {
        this.admin.getLabFromCat(cat, type).subscribe((data: any) => {
            console.log(data);
            resolve(data.lab_id);
        }, error => {
            reject(error);
        });
    });
}


  getLabId(stax, subcat){
    let arrayLabs = []
    console.log(subcat)
    for(let x = 0; x < subcat.length; x++){
      console.log(subcat[x])
    for (let i = 0; i < subcat[x].stacks.length; i++){
      if(subcat[x].stacks[i].stax_type == stax){
        arrayLabs.push(subcat[x].stacks[i].lab_id)
      }
    }
  }
  return arrayLabs
}

  checkTransStax(){
    let target: string;
    if (this.subcategory != undefined && this.subcategory.campus_name != undefined && this.multipleLevelsStax.indexOf(this.currentStaxType) > -1) {
      target = 'all';
    } else {
      target = null;
    }

    let labId = [];

    const getLabIds = async () => {
      if (this.checkedCats.length > 0) {
          console.log(this.checkedCats);

          for (let i = 0; i < this.checkedCats.length; i++) {
              try {
                  const lab_id = await this.getLabFromCat(this.checkedCats[i].cat_id, this.currentStaxType);
                  labId.push(lab_id);
              } catch (error) {
                  console.error(`Error fetching lab_id for cat_id ${this.checkedCats[i].cat_id}:`, error);
                  // Manejar errores si es necesario
              }
          }
      }

      if (this.checkedSubcats.length > 1) {
          for (let i = 0; i < this.checkedSubcats.length; i++) {
              if (this.checkedSubcats[i].cat == 'Cluster') {
                  console.log(this.checkedSubcats[i].subcategories);
                  this.checkedSubcats.push(...this.checkedSubcats[i].subcategories);
                  this.checkedSubcats.splice(i, 1);
              }
          }
          labId.push(...this.getLabId(this.currentStaxType, this.checkedSubcats));
      }
  };

  // Llamar a la función asincrónica para obtener los lab_id
  getLabIds().then(() => {

    this.EditorText = this.EditorText.replace(/<\/p><p>/g, ',');

    this.admin.checkTransStax(this.EditorText, this.currentStaxType, labId, target).subscribe((data: any) => {

      let frontObjectArray = data['trans'];

      let staxContentAux = new Array();
      let text: string;
      let separator;

      switch (this.currentStaxType) {
        case 'WL':
        case 'TERM':
          separator = ': ';
          break;
          case 'GRAM':
          case 'IDIOM':
          case 'JOB':
          case 'ACADEMIC':
          separator = '&&& ';
          break;
        default:
          separator = ': ';
          break;
      }

      let index = 1;


      frontObjectArray.forEach(element => {
        console.log(element)


          text = '<p>'  + ' ' + '<span style="background-color:#98FB98;" >' + element.word + '</span>';


        staxContentAux.push(text);

        if (element.translations != undefined) {
          staxContentAux.push(separator);

          const translationsArray = this.processArray(element.translations, 'translation');

          staxContentAux.push(...translationsArray);

        }

        if (element.definitions != undefined) {
          staxContentAux.push(separator);
          const definitionsArray = this.processArray(element.definitions, 'definition');

          staxContentAux.push(...definitionsArray);
        }

        if (element.example != undefined) {
          staxContentAux.push(separator);

          if (element.duplicated) {
            text = '';
          } else if (element.example.existed) {
            text = element.example['example'];
          } else {
            text = '<span style="background-color:#98FB98;" >' + element.example['example'] + '</span>';
          }

          staxContentAux.push(text);
        }

        if (element.pos != undefined) {
          staxContentAux.push(separator);

          staxContentAux.push(element.pos);
        }

        index++;

        staxContentAux.push('</p>');
      });

      this.textEditionWL = staxContentAux.join('');
      this.dialog.open(StaxTransModalComponent, {
        data: this.textEditionWL,
        height: '90%',
        width: '90%'
      });
      this.explodeChunk()
      this.checkStax()

    });
  }).catch((error) => {
      console.error('Error fetching lab_ids:', error);
      // Manejar errores si es necesario
  })
  }


  clusters
  getClusters(){
    this.admin.getClusters().subscribe((data: any) => {
      console.log(data)
      this.clusters = data
    }
    )
  }

  historialMessages = new Array();
  textEditionWL;
  charging = false;
  selectedCatMacro

  checkStax() {



    this.charging = true;
    let target;
    if (this.subcategory != undefined && this.subcategory.campus_name != undefined && this.multipleLevelsStax.indexOf(this.currentStaxType) > -1) {
        target = 'all';
    } else {
        target = null;
    }

    let labId = [];
    // Función para obtener lab_id de cada categoría y subcategoría seleccionada
    const getLabIds = async () => {
        if (this.checkedCats.length > 0) {
            console.log(this.checkedCats);

            for (let i = 0; i < this.checkedCats.length; i++) {
                try {
                    const lab_id = await this.getLabFromCat(this.checkedCats[i].cat_id, this.currentStaxType);
                    labId.push(lab_id);
                } catch (error) {
                    console.error(`Error fetching lab_id for cat_id ${this.checkedCats[i].cat_id}:`, error);
                    // Manejar errores si es necesario
                }
            }
        }

        if (this.checkedSubcats.length >= 1) {
          console.log(this.checkedSubcats)
          for (let i = 0; i < this.checkedSubcats.length; i++) {
                if (this.checkedSubcats[i].cat == 'Cluster') {
                    console.log(this.checkedSubcats[i].subcategories);
                    this.checkedSubcats.push(...this.checkedSubcats[i].subcategories);
                    this.checkedSubcats.splice(i, 1);
                }
                else{
                  continue
                }
            }
            labId.push(...this.getLabId(this.currentStaxType, this.checkedSubcats));
        }
    };



    // Llamar a la función asincrónica para obtener los lab_id
    getLabIds().then(() => {
        console.log("INFO CALL", labId, this.currentStaxType, this.EditorText, target);




        // Llamar a la API admin.checkStax con los parámetros necesarios
        this.admin.checkStax(this.EditorText, this.currentStaxType, this.selectedKey, labId, target).subscribe(
          (data: any) => {
            this.charging = false;
            console.log("checkkkkStax", data);
            for (const message of data['messages']) {
              if (message !== '') {
                this.messageArray.push(message);
                this.historialMessages.push(message);

              }
            }
            this.wordStaxObjectArray = data['back_object'];
            let frontObjectArray = data['front_object'];

            let staxContentAux = [];

            // Generar contenido HTML basado en la respuesta
            let separator = this.selectedKey;
            let index = 1;


            frontObjectArray.sort((a, b) => {
              if (!a.duplicated && !a.existed && a.labword) return -1;
              if (a.labword && !a.duplicated && !a.existed) return -1;
              if (a.existed && !a.duplicated) return -1;
              if (a.duplicated) return 1;
              return 0;
          });


            frontObjectArray.forEach(element => {
                console.log(element);

                let text;

              if(element.duplicated && !element.labword && !element.existed) {
                  text = ``;
              }
              else if (element.existed && !element.duplicated && !element.labword) {
                text = `<p><strong><mark style="background-color:hsl(0, 0%, 30%);">&nbsp;</mark></strong> <mark style="background-color:hsl(0, 0%, 30%);">${element.word}</mark>`;

              }  else if (element.labword && !element.existed && !element.duplicated && element.existed_dictionaryword) {
                  text = `<p><strong><mark class="marker-pink">&nbsp;</mark></strong> <mark class="marker-green">${element.word}</mark>`;
              }
                  else if(!element.existed_dictionaryword){
                    text = `<p><strong><mark class="marker-pink">&nbsp;</mark><mark class="marker-blue">&nbsp;</mark></strong> <mark class="marker-green">${element.word}</mark>`;
              }
              if(element.duplicated){
                if(this.currentStaxType == 'WL'){
                  text = ''
                }
                else{
                text = `<span style="background-color:hsl(0, 0%, 30%); color:white">${element.word}</span>`
              }
              }

                staxContentAux.push(text);

                if (element.translations != undefined && element.translations.length > 0) {
                    staxContentAux.push(separator);
                    const translationsArray = this.processArray(element.translations, 'translation');
                    staxContentAux.push(...translationsArray);
                }

                if (element.definitions != undefined) {
                  console.log("def")
                    staxContentAux.push(separator);
                    const definitionsArray = this.processArray(element.definitions, 'definition');
                    staxContentAux.push(...definitionsArray);
                }

                if (element.example != undefined) {
                  console.log("exam")
                    staxContentAux.push(separator);
                    let exampleText;
                     if (element.example.existed) {
                        exampleText = element.example['example'];
                    } else {
                        exampleText = `<mark class="marker-green">${element.example['example']}</mark>`;
                    }
                    staxContentAux.push(exampleText);
                }

                if (element.pos != undefined) {
                  console.log("pos")
                    staxContentAux.push(separator);
                    staxContentAux.push(element.pos);
                }

                index++;

                staxContentAux.push('</p>');
            });

            this.textEditionWL = staxContentAux.join('');
            this.EditorText = this.textEditionWL;
        },
        (error) => {

          if(this.currentStaxType == ''){
            alert('Selecciona un Stax')
          }
          if(this.EditorText == ''){
            alert('Escribe algo en el editor')
          }
          if(this.selectedKey == ''){
            alert('Selecciona una clave')
          }
          if(this.checkedCats.length == 0 && this.checkedSubcats.length == 0){
            alert('Selecciona al menos una categoría o subcategoría')
          }
          else{
            alert('Ha ocurrido un error desconocido, compruebe que todo esta correcto y vuelve a intentarlo');
          }



          this.charging = false; // Asegúrate de que se detiene la carga
      });
    }).catch((error) => {
        console.error('Error fetching lab_ids:', error);
        this.charging = false;
        // Manejar errores si es necesario
    });
}

  processArray(elements: any[], propertyName: string): string[] {
    const processedArray: string[] = [];

    elements.forEach((element: any) => {
      console.log(element)
      if (element.lab_id == null) {
        processedArray.push('<span style="background-color: #62f962">' + element[propertyName] + '</span>, ');
      }  else {
        processedArray.push('<span style="background-color: #62f962">' + element[propertyName] + '</span>, ');
      }
    });

    // se quita la última coma
    if (processedArray.length > 0) {
      processedArray[processedArray.length - 1] = processedArray[processedArray.length - 1].replace(/(^[,\s]+)|([,\s]+$)/g, '');
    }

    return processedArray;
  }


  insertPlantilla() {
    let text = this.EditorText;
    let lines = text.split('<br>'); // Primer split por las líneas
    let processedLines = [];

    // Recorremos cada línea
    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];

      if (line === '' || line === ' ') {
          continue;
      }

      // Cambiar ':' por '$$' en caso de que la key sea ':'
      if (this.selectedKey === ':') {
          line = line.replace(':', '$$$$'); // Reemplazar ':' por '$$'
      }

      // Actualizar la línea modificada en el array lines
      lines[i] = line;
  }
        if (this.selectedKey === ':') {
          this.selectedKey = '$$'; // Cambiar la clave seleccionada a '$$'
          }

        for (let line of lines){
        // Segundo split basado en la clave (puede ser ':' o lo que sea seleccionado)
        let items = line.split(this.selectedKey);

        if(this.selectedKey == '&&'){
          this.selectedKey = '&amp;&amp;'
        }

        if (!this.checkboxes.trad) {
            items[0] = items[0] + this.selectedKey;
        }
        if (!this.checkboxes.def) {
            items[1] = items[1] + this.selectedKey;
        }
        if (!this.checkboxes.ex && items.length >= 2) {
            if (items.length == 2) {
                items[1] = items[1] + this.selectedKey;
            } else {
                items[2] = items[2] + this.selectedKey;
            }
        }

        processedLines.push(items.join(this.selectedKey));
    }


    if(this.selectedKey == '&amp;&amp;'){
      this.selectedKey = '&&'
    }


    this.EditorText = processedLines.join('</br>');
}





  @ViewChild('keySelect') keySelect: ElementRef;

  onChange({ editor }: ChangeEvent) {
    this.EditorText = editor.getData();

    // Definir las posibles claves
    const keys = [ '$$$$' ,'$$$', '&amp;&amp;&amp;', '&amp;&amp;', '$$', ':', ','];

    // Comprobar si el contenido del editor contiene alguna de las claves
    const foundKey = keys.find(key => this.EditorText.includes(key));


    console.log("founded????",foundKey)

    console.log(this.EditorText)

// Decodear &&&amp

    // Si se encuentra una clave, asignarla a this.selectedKey
    if (foundKey) {
      console.log(foundKey)

      if(foundKey == '&amp;&amp;&amp;'){
        this.selectedKey = '&&&';
        console.log("this.selectedKey", this.selectedKey)
      }
      else if(foundKey == '&amp;&amp;'){
        this.selectedKey = '&&';
        console.log("this.selectedKey", this.selectedKey)
      }
      else if(foundKey == '$$$$'){
      this.selectedKey = '$$';
      console.log("this.selectedKey", this.selectedKey)

      }
      else{
        this.selectedKey = foundKey;
      }

      // Actualizar el valor del <select>
      this.keySelect.nativeElement.value = this.selectedKey;
    }
    this.setPlaceHolder();
  }

  @ViewChild('tooltipContainer') tooltipContainer: ElementRef;

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }

  handleClickOutside(event: Event) {
    if (!this.tooltipContainer.nativeElement.contains(event.target)) {
      this.showTooltip = false;
    }
  }




  selectedKey: string = ':'; // Valor por defecto


  onChangeKey(event: Event) {
    this.selectedKey = (event.target as HTMLSelectElement).value;
    console.log(this.selectedKey);
  }




  // callHistorial(){
  //   this.messageArray.push("HOLAA");
  //   this.cdr.detectChanges(); // Forzar la actualización de la vista
  // }
  subcatWord;

  searchingSubcat: boolean = false;
  dropDownSearch: boolean = false;
  searchedSubcats = new Array();
  searchSubcat(search_value) {
    this.searchingSubcat = true;
    search_value = search_value.toLowerCase();
    let coursesAux = new Array();

    if (search_value.length > 2) {
      this.searchedSubcats = new Array();
      this.checkedSubcats = new Array();

      this.campusTypes[1].subject.forEach(cat => {
        cat.subcats.forEach(subcat => {
          if (subcat.subcat_name != undefined) {
            if (subcat.subcat_name.toLowerCase().indexOf(search_value) != -1) {
              subcat.cat = cat.cat_name;
              subcat.cat_id = cat.cat_id;
              if (this.currentStaxType != undefined) {
                subcat.stacks.forEach(st => {
                  if (st.stax_type == this.currentStaxType) {
                    subcat.num_subcat = st.num_subcat;
                    subcat.num_cat = st.num_cat;
                    subcat.num_publisher = st.num_publisher;
                  }
                });
              }
              this.searchedSubcats.push(subcat);
              this.dropDownSearch = true;
            }
          }
        })
      });





    }
  }



  countSubcatsChecked(subcats){
    let count = 0
    for( let i = 0; i < subcats.length; i++){
      if(subcats[i].checked){
        count++
      }
    }

    return count
  }

  ClusterSubcats = new Array();
  setSearchedSubcats(subcat, event?, cat?) {

    let clusterized = false

    let mycat = ''
    if(cat != undefined){
      mycat = cat.cat_name
    }
    else{
      mycat = subcat.cat
    }
    if (event.target.checked) {
      if(!this.checkedSubcats.includes(mycat)){
      subcat.checked = true;
      for(let i = 0; i < this.clusters.length; i++){
        for(let x = 0; x < this.clusters[i].subcategories.length; x++){
          if(this.clusters[i].subcategories[x].subcat_id == subcat.subcat_id){
            console.log(this.clusters[i].subcategories[x])
            clusterized = true
            this.clusters[i].subcategories.forEach(subcategory => {

              this.campusTypes[1].subject.forEach(cat => {
                console.log(this.campusTypes[1].subject)
                cat.subcats.forEach(subcat => {
                  if(subcat.subcat_id == subcategory.subcat_id){
                    subcat.checked = true;
                    subcat.cluster = true;
                    this.checkedSubcats.push(subcat)
                  }
                })
              }
              )
          });
          // Agregar todas las subcategorías de este cluster a checkedSubcats
          this.ClusterSubcats.push(...this.clusters[i].subcategories)
          }
        }
      }

      if(!clusterized){
        this.checkedSubcats.push(subcat);
      }
      subcat.cat = mycat
      }

    } else {
      let index = this.checkedSubcats.indexOf(subcat);
      subcat.checked = false;
      this.checkedSubcats.splice(index, 1);
      this.ClusterSubcats = this.ClusterSubcats.filter(subcategory => subcategory.subcat_id != subcat.subcat_id)
    }

    console.log(this.checkedSubcats)

  }

  UncheckFromCluster(subcat){
    this.ClusterSubcats = this.ClusterSubcats.filter(subcategory => subcategory.subcat_id != subcat.subcat_id)
    this.checkedSubcats = this.checkedSubcats.filter(subcategory => subcategory.subcat_name != subcat.subcat_name)
    this.campusTypes[1].subject.forEach(cat => {
      cat.subcats.forEach(subct => {
        if(subcat.subcat_name == subct.subcat_name){
          subct.checked = false;
        }
      })
    })

    console.log(this.checkedSubcats)
  }


  checkCatSelected(cat){
    let checked = false;
    for(let i = 0; i < this.checkedCats.length; i++){
      if(this.checkedCats[i].cat_name == cat){
        return true
      }
    }
    return checked
  }



  setSearchedCats(cat: any, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      // Desmarcar todos los checkboxes y limpiar checkedCats
      this.checkedCats.forEach(selectedCat => {
        selectedCat.checked = false;
        selectedCat.subcats.forEach(subcat => subcat.checked = false);
      });
      this.checkedCats = []; // Limpia la lista de checkboxes seleccionados

      // Marca el checkbox actual
      cat.checked = true;
      this.checkedCats.push(cat);
    } else {
      // Si se desmarca el checkbox actual, elimínalo de la lista
      const index = this.checkedCats.indexOf(cat);
      if (index > -1) {
        this.checkedCats.splice(index, 1);
      }
      cat.checked = false;
    }
  }

  isCheckboxDisabled(course: any): boolean {
    // Solo deshabilita si hay algún checkbox seleccionado y el curso no está seleccionado
    return this.checkedCats.length > 0 && !course.checked;
  }

  messageArray = new Array();
  isDisabled = false
  async setStaxLab() {
    this.charging = true;
    this.isDisabled = true;
    let level = [];

    // Determinar cat_id y subcat_id
    let cat_id = this.catImporter?.cat_id;
    let subcat_id = this.subcategory?.subcat_id;
    let course_id = this.subcategory?.course_id;

    // Si hay múltiples niveles y campus_name está definido en subcategory
    if (this.subcategory && this.subcategory.campus_name && this.multipleLevelsStax.includes(this.currentStaxType)) {
      subcat_id = null;
      let target = 'all';
      try {
        const data = await this.admin.setStaxLab(
          this.EditorText,
          this.wordStaxObjectArray,
          this.currentStaxType,
          level,
          null,  // No se pasa cat_id
          null,  // No se pasa subcat_id
          course_id,
          this.subcategory.campus_id,
          this.selectedKey,
          target
        ).toPromise();

        this.messageArray.push(data[0]);
        this.isDisabled = false;
        this.charging = false;
      } catch (error) {
        console.error('Error al guardar:', error);
        this.isDisabled = false;
        // Manejar el error si es necesario
      }
    } else if (this.checkedSubcats.length === 0 && this.checkedCats.length === 0) {
      // Si no hay subcategorías ni categorías seleccionadas
      try {
        const data = await this.admin.setStaxLab(
          this.EditorText,
          this.wordStaxObjectArray,
          this.currentStaxType,
          level,
          cat_id,
          subcat_id,
          course_id,
          this.campus_id,
          this.selectedKey,
        ).toPromise();

        this.messageArray.push(data[0]);
        this.charging = false;
        this.isDisabled = false;
      } catch (error) {
        console.error('Error al guardar:', error);
        this.isDisabled = false;
        // Manejar el error si es necesario
      }
    } else {
      // Si hay checkedSubcats o checkedCats
      try {
        if (this.checkedSubcats.length > 0) {
          // Procesar checkedSubcats uno por uno en secuencia
          for (let i = 0; i < this.checkedSubcats.length; i++) {
            const data = await this.admin.setStaxLab(
              this.EditorText,
              this.wordStaxObjectArray,
              this.currentStaxType,
              level,
              cat_id,
              this.checkedSubcats[i].subcat_id,
              this.checkedSubcats[i].course_id,
              this.campus_id,
              this.selectedKey,
            ).toPromise();
            console.log(this.checkedSubcats[i].subcat_id)
            this.messageArray.push('Guardado ' + this.checkedSubcats[i].subcat_name);
          }
        } else if (this.checkedCats.length > 0) {
          // Procesar checkedCats uno por uno en secuencia
          for (let i = 0; i < this.checkedCats.length; i++) {
            const data = await this.admin.setStaxLab(
              this.EditorText,
              this.wordStaxObjectArray,
              this.currentStaxType,
              level,
              this.checkedCats[i].cat_id,
              subcat_id,
              course_id,
              this.campus_id,
              this.selectedKey
            ).toPromise();
            console.log(this.checkedCats)
            this.messageArray.push('Guardado: ' + this.checkedCats[i].cat_name);
          }
        }
        this.isDisabled = false;
        this.charging = false;
      } catch (error) {
        console.error('Error al guardar:', error);
        this.isDisabled = false;
        // Manejar el error si es necesario
      }
    }
  }

  checkboxes = {
    trad: false,
    def: false,
    ex: false,
    pos: false
  };

  green: boolean = false;
  onCheckboxChange(event: any, key: string) {
    console.log(this.checkboxes)
    this.checkboxes[key] = event.target.checked;
  }


  onCheckbox(type: string): void {
    console.log(this.currentStaxType)
    if(this.currentStaxType== 'GRAM' || this.currentStaxType == 'IDIOM' ||  this.currentStaxType== 'ACADEMIC' || this.currentStaxType == 'JOB'){
      console.log(this.currentStaxType)
      console.log(this.checkboxes.trad, this.checkboxes.def, this.checkboxes.ex)
      if(this.checkboxes.trad && this.checkboxes.def && this.checkboxes.ex){
        this.green = false
      }
      else{
        this.green = true
      }
    }

  }




  cloneStax() {
    let checkedSubcatsIds = this.checkedSubcats.map(subcat => subcat.subcat_id);
    this.admin.cloneStax(this.stax['lab_id'], checkedSubcatsIds, this.currentStaxType).subscribe(data => {
      this.messageArray.push("Guardado correctamente");
    }, err => {
      this.messageArray.push("Ha habido un problema");
    });
  }

  removeMessage() {
      this.messageArray = [];

  }

  explodeChunk() {
    this.green = false;
    if(this.currentStaxType == 'WL'){
    console.log(this.EditorText);

    // Extraer el contenido de texto dentro de las etiquetas <p>
    let textContent = this.EditorText.replace(/<\/?p>/g, '');

    // Dividir el texto en palabras usando la coma como separador y eliminar espacios en blanco
    let words = textContent.split(',').map(word => word.trim());

    // Eliminar las palabras duplicadas usando un conjunto (Set)
    let uniqueWords = [...new Set(words)];

    // Unir las palabras únicas con <br>
    let formattedText = uniqueWords.join('<br>');

    // Envolver el texto formateado con etiquetas <p>
    this.EditorText = `<p>${formattedText}</p>`;
    }

    else{
        this.insertPlantilla()
    }


  }

  handleClose(){

    if(this.showTooltip == true){
      this.showTooltip = false;
    }
    else{
      return
    }
  }


  setPusherData(data) {
    let campus = data.campus_id;
    let levelCampus;
    if (campus == 1) { //nivel
      levelCampus = 'levels';
      campus = 0;
    } else if (campus == 2) {
      levelCampus = 'subject';
      campus = 1;
    }
    let stackInfo = JSON.parse(data.stack);
    let id = data.id;
    this.campusTypes[campus][levelCampus].forEach((level, indexLevel) => {
      if (data.campus_id == 1) {
        if (level.id == id) {
          level.stacks.forEach((stack, indexStax) => {
            if (stack.lab_id == stackInfo.lab_id) {
              this.campusTypes[campus][levelCampus][indexLevel].stacks[indexStax] = stackInfo;
              return
            }
          });
        }
      } else {
        level.subcats.forEach((subcat, indexSubcat) => {
          if (subcat.subcat_id == id) {
            subcat.has_content = true;
            subcat.stacks.forEach((stack, indexStax) => {
              if (stack.stax_type == stackInfo.stax_type) {
                this.campusTypes[campus][levelCampus][indexLevel].subcats[indexSubcat].stacks[indexStax] = stackInfo;
                return
              }
            });
          }
        });
      }
    });
  }



  getStaxContent(import_date?) {
    let subcat_id;

    if (this.subcategory != undefined) {
      subcat_id = this.subcategory.subcat_id

      if (this.currentStaxType != 'Not in dict') {
        this.callCheckStax = false;
      }

      this.admin.getStaxLab(this.stax['lab_id'], subcat_id, this.currentStaxType).subscribe((data: any) => {
        let separator;
        switch (this.currentStaxType) {
          case 'WL':
            separator = ' : ';
            break;
          case 'TERM':
          case 'JOB':
          case 'GRAM':
          case 'IDIOM':
          case 'ACADEMIC':
            separator = ' &&& ';
            break;
          default:
            separator = ' : ';
            break;
        }

        this.stax['lab_words'] = data;
        let staxContentAux = new Array();
        let title = '<h1> </h1>'
        let index: number;
        for (let i = 0; i < data.length; i++) {
          index = i + 1;

          staxContentAux.push('<p>' + data[i].word);

          if (data[i].translations !== undefined || data[i].translations.length > 0) {
            staxContentAux.push(separator);

            data[i].translations.forEach(element => {
              staxContentAux.push(element + ', ');
            });

            staxContentAux[staxContentAux.length - 1] = staxContentAux[staxContentAux.length - 1].replace(/(^[,\s]+)|([,\s]+$)/g, '');
          }

          if (data[i].definitions !== undefined) {

            staxContentAux.push(separator);

            data[i].definitions.forEach(element => {
              staxContentAux.push(element + ', ');
            });
            staxContentAux[staxContentAux.length - 1] = staxContentAux[staxContentAux.length - 1].replace(/(^[,\s]+)|([,\s]+$)/g, '');
          }

          if (data[i].example !== undefined) {
            staxContentAux.push(separator);
            staxContentAux.push(data[i].example);

          }

          if (data[i].pos !== undefined) {
            staxContentAux.push(separator);
            staxContentAux.push(data[i].pos);
          }

          staxContentAux.push('</p>');

        }
        this.EditorText = title + staxContentAux.join('');
      });
    }


  }

  // onReady(editor) {
  //   editor.ui
  //     .getEditableElement()
  //     .parentElement.insertBefore(
  //       editor.ui.view.toolbar.element,
  //       editor.ui.getEditableElement(),
  //     );
  // }



  pasteLargeText(text: string, editorInstance: any): void {
    const lines = text.split('\n');
    const chunkSize = 100; // Número de líneas por iteración
    let currentIndex = 0;

    const insertChunk = () => {
      const chunk = lines.slice(currentIndex, currentIndex + chunkSize).join('\n');
      editorInstance.model.change((writer: any) => {
        const insertPosition = editorInstance.model.document.selection.getFirstPosition();
        writer.insertText(chunk, insertPosition);
      });
      currentIndex += chunkSize;

      if (currentIndex < lines.length) {
        setTimeout(insertChunk, 50); // Evitar bloquear la UI
      }
    };

    insertChunk();
  }



  onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      );
  }


  cleanTextEditor() {
    this.EditorText = '';
    this.textEditionWL = undefined;
    this.messageArray = new Array();
    this.checkedSubcats = []
    this.checkedCats = []
    this.ClusterSubcats = []

    this.campusTypes[1].subject.forEach(cat => {
      cat.checked = false;
      cat.subcats.forEach(subcat => {{
          subcat.checked = false;
          subcat.cat = cat.id;
        }
      })
    })

  }



}
