<div class="chat-container">
  <div class="contacts-panel">
      <div class="contacts-header">Contacts</div>
      <!-- <div class="contact selected">John Doe</div>
      <div class="contact">Jane Smith</div>
      <div class="contact">Alice Johnson</div>
      <div class="contact">Robert Brown</div> -->

      <div class="contact" *ngFor="let chat of allChats"(click)="selectChat(chat)" [class.selected]="chat === selectedChat"
      [class.has-new-message]="chat.hasNewMessage">
      <div [ngClass]="{'sc-type': chat.type === 'SC', 'st-type': chat.type === 'ST', 'sp-type': chat.type === 'SP'}" style="width: 12px; height: 100%; margin-right: 12px;"></div>
      <span style="width: 40%;">{{chat.name}} {{chat.last_name}}</span><span style='font-size: 0.6rem; color: grey; ' >{{chat.created_at | date: 'short'}}</span>
        <span *ngIf="chat.hasNewMessage" class="new-message-indicator">•</span>
        <select name="tipoAssist" id="tipoAssist" [(ngModel)]="chat.type" (change)="changeTipoAssist(chat.id, chat.type)">
          <option value="SC">SIN CALIFICAR</option>
          <option value="ST">SOPORTE TÉCNICO</option>
          <option value="SP">SOPORTE PLATAFORMA</option>
        </select>
</div>


  </div>

  <div class="chat-panel">
      <div class="chat-header" *ngIf="chatSelected.id != 0"><span>{{chatSelected.name}} {{chatSelected.last_name}}</span>
        <span (click)="closeTicket(chatSelected.id)" style="background-color: red; color: white; padding:0 6px; border-radius: 6px; cursor: pointer;">CERRAR TICKET</span></div>
      <div #chatMessagesContainer class="chat-messages" *ngIf="chatSelected.id != 0">
          <!-- <div class="message received">Hi there!</div>
          <div class="message sent">Hello, how can I help you?</div> -->
            <div class="message" [ngClass]="{'sent': msg.role == 18 || msg.role == 9, 'received': msg.role != 18 && msg.role != 9}" *ngFor="let msg of chatMessages">
            <strong>{{ msg.user_name }}</strong> <!-- Nombre del usuario -->
            <p>{{ msg.message }}</p> <!-- Mensaje -->
            <small>{{ msg.created_at | date: 'short' }}</small> <!-- Fecha del mensaje formateada -->
            </div>
      </div>
      <div class="chat-input" *ngIf="chatSelected.id != 0">
          <input type="text" [(ngModel)]="newMessage" placeholder="Type a message...">
          <button (click)="sendMessage(newMessage)">Send</button>
      </div>
  </div>
</div>
