import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import Editor from "src/assets/js/ckeditor.js";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { AdminService } from 'src/app/services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalGapComponent } from '../modals/modal-gap/modal-gap.component';
declare const CKEDITOR




interface Stax {
  title: string;
  type: string;
  content: string;
  topic?: string;
  unit?: string;
}


@Component({
  selector: 'app-gapper-importer',
  templateUrl: './gapper-importer.component.html',
  styleUrls: ['./gapper-importer.component.css']
})
export class GapperImporterComponent {


      constructor(public admin : AdminService, public dialog: MatDialog) { }

    public Config = {
      removePlugins: ['Title'],
    };

    @ViewChild("myEditor") myEditor: any;
    public Editor = Editor;
    public EditorText: string = "Title: ";




    onReady(editor) {
      console.log(this.myEditor);
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement(),
        );
    }


    readingTypes = []
    selectedType = " "

    ngOnInit() {
      this.admin.getTypeReading().subscribe((data: any) => {

        for (let i = 0; i < data.length; i++) {
          if(data[i].type == "Description") {
            data[i].type = "Chart"
            this.readingTypes.push(data[i])

          }
          if(data[i].type == "article" || data[i].type == "Article") {
            data[i].type = "Article"
            this.readingTypes.push(data[i])
          }
          if(data[i].type == "Job Interview" || data[i].type == "job interview") {
            data[i].type = "Job Interview"
            this.readingTypes.push(data[i])
          }
        }


    })

    }

    selectedNgap


    openGapModal() {
      if(this.wordsToColor)
      this.dialog.open(ModalGapComponent, {
        width: '60%',
        height: '90%',
        data: {gap: this.wordsToColor, text: this.groupTexts}

      })
    }




    finalize = false;
    onChange({ editor }: ChangeEvent) {
      this.EditorText = editor.getData();
      // if (this.finalize == false ) {
      //   setTimeout(() => {
      //   this.checkReadingContent()
      //   }, 300);
      // }
    }
    wordsToColor
    audioList
    saveButton = false

    groupTexts

    checkText(){
      this.groupTexts = this.separateText(this.EditorText, this.selectedType);
      let serializedTexts = JSON.stringify(this.groupTexts);

      this.admin.gapByFreq(serializedTexts, this.selectedPlan, this.selectedType, this.selectedNgap, 1).subscribe((data: any) => {
        console.log("1",data);
        this.wordsToColor = data;

        if (this.wordsToColor) {
            this.admin.gapByFreq(serializedTexts, this.selectedPlan, this.selectedType, this.selectedNgap, 2).subscribe((data2: any) => {
                this.audioList = data2;
                console.log("2",data2);
                this.saveButton = true;
            });
        }
    });
    }

    checkMicroText(){
    }




    modeMicroGap = false
    changeMode(){
      this.modeMicroGap = !this.modeMicroGap
    }



    saveText() {
    let texts = this.separateText(this.EditorText, this.selectedType);
    let serializedTexts = JSON.stringify(texts);

    for (let i = 0; i < texts.length; i++) {
      this.admin.createGappedText(texts[i].title,
        texts[i].content, this.selectedType, this.wordsToColor[2][i], this.wordsToColor[2][i].length, this.selectedPlan, texts[i].topic, this.audioList[2][i]).subscribe((data: any) => {
        // this.splitText(this.EditorText, this.selectedNgap)
        this.saveButton = false

      console.log(data)
   })



    }






  }


    // splitText(text, ngap) {
    //   let words = text.split(/\s+/);

    //   let startIndex = 0;
    //   let startPinkIndex = 0;

    //   // Itera sobre cada lista de palabras en this.wordsToColor[2]
    //   this.wordsToColor[2].forEach((wordList) => {
    //       wordList.forEach((word) => {
    //           const regex = new RegExp('\\b' + word + '\\b', 'gi');

    //           // Solo busca a partir del índice donde se encontró la última palabra
    //           for (let i = startIndex; i < words.length; i++) {
    //               if (regex.test(words[i])) {
    //                   // Se encontró la palabra, resáltala
    //                   words[i] = `<span style="background-color: lightgreen; ">${words[i]}</span>`;
    //                   // Actualiza el índice de inicio para la próxima palabra
    //                   startIndex = i + 1;
    //                   break; // Sal del bucle una vez encontrada la palabra
    //               }
    //           }
    //       });
    //   });


    //   this.wordsToColor[1].forEach((word) => {
    //         const regex = new RegExp('\\b' + word + '\\b', 'gi');

    //         // Solo busca a partir del índice donde se encontró la última palabra
    //         for (let i = startPinkIndex; i < words.length; i++) {
    //             if (regex.test(words[i])) {
    //                 // Se encontró la palabra, resáltala
    //                 words[i] = `<span style="background-color: pink;">${words[i]}</span>`;
    //                 // Actualiza el índice de inicio para la próxima palabra
    //                 startPinkIndex = i + 1;
    //                 break; // Sal del bucle una vez encontrada la palabra
    //             }
    //         }


    //   // Reensambla las palabras en el texto
    //   let lines = [];
    //   let currentLine = [];

    //   words.forEach(word => {
    //       currentLine.push(word); // Agrega la palabra a la línea actual

    //       // Si la línea actual alcanza 15 palabras, agrégala a las líneas y comienza una nueva línea
    //       if (currentLine.length === 15) {
    //           lines.push(currentLine.join(' ')); // Agrega la línea actual a las líneas
    //           currentLine = []; // Reinicia la línea actual
    //       }
    //   });

    //   // Agrega la última línea si tiene palabras
    //   if (currentLine.length > 0) {
    //       lines.push(currentLine.join(' '));
    //   }

    //   // Reensambla las líneas en el texto
    //   this.EditorText = lines.join('\n');


    //   this.myEditor.editorInstance.setData(this.EditorText);
    // })

    // }

  selectedPlan



    // Función para separar el texto en objetos
    separateText(text, type) {
      const staxList = [];
      let regex
      if(type =="Job Interview") {
        regex = /(?:Topic:\s*(.*?)\s*)?(?:Unit:\s*(.*?)\s*)?(?:QQQ:\s*(.*?)\s*)(ANSR):\s*([\s\S]*?)(?=Unit:|Topic:|Title:|QQQ|$)/gs;
      }
      if(type == "Article") {
        regex = /(?:Topic:\s*(.*?)\s*)?(?:Unit:\s*(.*?)\s*)?(?:Title:\s*(.*?)\s*)(Article):\s*([\s\S]*?)(?=Unit:|Topic:|Title:|Article|$)/gs;
      }
      if(type == "Chart") {
        regex = /(?:Topic:\s*(.*?)\s*)?(?:Unit:\s*(.*?)\s*)?(?:Title:\s*(.*?)\s*)(Description|Presentation Monologue):\s*([\s\S]*?)(?=Unit:|Topic:|Title:|Description|Presentation Monologue|$)/gs;
      }
      if(type == "Expertise"){
        regex = /(?:Topic:\s*(.*?)\s*)?(?:Unit:\s*(.*?)\s*)?(?:Title:\s*(.*?)\s*)(Expertise):\s*([\s\S]*?)(?=Unit:|Topic:|Title:|Expertise|$)/gs;
      }
  let match;
  let currentTopic = null;
  let currentUnit = null;

  while ((match = regex.exec(text)) !== null) {
    console.log("match", match)
      const topic = match[1] ? match[1].trim() : currentTopic;
      // const unit = match[2] ? match[2].trim() : currentUnit;
      const title = match[3].trim();
      const type = match[4].trim();
      let content = match[5].trim().replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, '');

      // Eliminar guiones adicionales
      content = content.replace(/---+/g, '');

      const stax: Stax = {
          title: title,
          type: type,
          content: content
      };

      if (topic) {
          stax.topic = topic;
          currentTopic = topic;
      }

      // if (unit) {
      //     stax.unit = unit;
      //     currentUnit = unit;
      // }


      staxList.push(stax);
  }
  console.log(staxList);
  return staxList;


    }





}
