import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserDataService } from 'src/app/services/user-data.service';
import { ModalExpandedContactsComponent } from '../modal-expanded-contacts/modal-expanded-contacts.component';

@Component({
  selector: 'app-modal-card-contact',
  templateUrl: './modal-card-contact.component.html',
  styleUrls: ['./modal-card-contact.component.css']
})
export class ModalCardContactComponent implements OnInit {

  isEditing = false;

  // Objeto editable con todos los datos
  editableContact: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userData: UserDataService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.editableContact = { ...this.data.contact };
    console.log('Editable Contact:', this.editableContact);
    let ultimaValoracion: any = this.editableContact.possibility.reduce((max: any, current: any) => {
      return current.id > max.id ? current : max;
  }, this.editableContact.possibility[0]); // Usa el primer elemento como valor inicial

  // Establece el valor del percentage más reciente
  this.value = ultimaValoracion.percentage;
  }

  toggleEditMode() {
    this.isEditing = !this.isEditing;

    if (!this.isEditing) {
      this.saveChanges();
    }
  }

  saveChanges() {
    // Crear un FormData con todos los valores editados
    const formData = new FormData();
    Object.entries(this.editableContact).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    console.log('FormData:', formData);
    // Aquí puedes enviar el FormData al backend si lo necesitas

    for (const [key, value] of (formData as any).entries()) {
      console.log(`${key}: ${value}`);
    }


    this.userData.updateContact(formData).subscribe(
      data => {
        console.log(data);
      }
    )


  }


  openModalPeople(){
    this.dialog.open(ModalExpandedContactsComponent, {
      width: '80%',
      data: {
        contact: this.editableContact
      }
    });
  }





  options: number[] = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90]; // Opciones del 0% al 90%

  value = 0;
    updateValue(): void {
      console.log(`Valor seleccionado: ${this.value}%`);
      this.userData.createNewProbability(this.value, this.editableContact.id).subscribe(data => {
        this.data.contact.possibility = data;
       })

  }


}
